import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { formActionCreators } from '../scripts/store/Form';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactToPrint from 'react-to-print';
import ContractDetails from './ContractDetails';
import "react-datepicker/dist/react-datepicker.css";
import '../stylesheets/form.css';
import FileBase64 from 'react-file-base64';
import { forEach } from 'lodash';
import moment from 'moment';
const FORM_ERRORS = 1;



class InvoiceFormContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            dlasContactName: '',
            dlasContactID: null,
            companyID: null,
            contractType: '',
            contractTypeID: null,
            year: null,
            dataChanged: false,
            id: this.props.match.params.id,
            submitted: false,
            showPopup: false,
            showSubmitPopup: false,
            period: '',
            agencyID: '',
            contractTypes: [],
            dlasContacts: [],
            companies: [],
            existingExemptions: [],
            exemption: '',
            citation: '',
            isRequisition: false,
            combineReq1: false,
            combineReq2: false,
            Type: '',
            contractItem: '',
            contractNumber: '',
            requisitionNumber: '',
            startDate: '',
            endDate: '',
            remainingRenewals: '',
            Price: '',
            companyName: '',
            companyAddress: '',
            companyCity: '',
            companyState: '',
            companyZipCode: '',
            companyFax: '',
            companyEmail: '',
            companyFin: '',

            contactName: '',
            contactPhone: '',
            contactFax: '',
            contactEmail: '',
            noteText: '',
            v: [],
            noteDetails: [
                {
                    textID: '',
                    noteText: '',
                    isDeleted: false,
                    files: [
                        {
                            fileName: '',
                            fileUrl: '',
                            mimeType: '',
                            fileID: 0,
                            byteArray: ''
                        }
                    ]
                }
            ],
            paperWeight: '',
            quantity: '',
            Pages: null,
            Volumes: null,
            combineOrderList: [
                {
                    combineOrderID: 0,
                    text: "",
                },
                {
                    combineOrderID: 1,
                    text: "1st",
                },
                {
                    combineOrderID: 2,
                    text: "2nd",
                },
            ],
            combineOrderID: null,
            newCompanyName: '',
            hideText: "hidden",
            hideButton: true,
            headerStyle: {
                float: 'left',
                width: '48%',
                marginTop: '5px',
                padding: '10px'
            },
            publisher: '',
            titleName: '',
            price: '',
            orderDate: '',
            receivedDate: '',
            paidDate: '',
            invoiceNumber: '',
            volume: '',
            accountRep: '',
            accountEmail: '',
            phoneNumber: '',
            fileName: '',
            attachement: '',
            uploadFile: new FormData(),
            uploadedFileName: "",
            publisherID: '',
            bytes: [],
            notes: '',
            form: new FormData(),
            file: null
        };

        this.props.actions.clickHeaderLink(false)
        this.handleChange = this.handleChange.bind(this);
        this.getInvoiceData = this.getInvoiceData.bind(this);
        this.saveInvoice = this.saveInvoice.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.toggleSubmitPopup = this.toggleSubmitPopup.bind(this);
        this.delete = this.delete.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }
    downloadFile() {
        return fetch('/api/Invoice/ByteArray/' + this.props.match.params.id, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            },
            //body: JSON.stringify(data)
        }).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = this.state.fileName;
                a.click();
            }).catch(error => {
                console.log(error)
            });
        })
    }
   
    handleFileChange(e) {
        this.setState({ file: e.target.files[0] })
       // let form = new FormData();
        this.state.form.append('image', e.target.files[0]);
        this.state.form.append('fileName', "Img");
        console.log(e.target.files[0])
        this.setState({ uploadFile: this.state.form, uploadedFileName: e.target.files[0].name });
        //this.setState({
        //    uploadFile: e.target.files[0]
        //})
    }
    handleDateChange(key, value) {
        this.setState({
            [key]: value
        });
    }

    handleChange(e) {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({
            [key]: value
        });
    }

    blurHandler() {
        this.setState({
            showPopup: false
        })
    }

    delete(id) {
        this.setState(prevState => ({
            v: prevState.noteDetails.forEach(function (o) {
                let item = o.files.filter(f => f.fileID == id)
                let index = o.files.findIndex(f => f.fileID == id)
                if (index > -1) {
                    item[0].isDeleted = true;
                    o.files[index] = item[0];
                }
            })
        }));
    }


    getInvoiceData(isSubmitting) {
        let formData = {};

        let hasErrors = 0;
        let nonEmptyNotes = [];


        formData = {
            ...formData,
            price: this.state.price,
            orderDate: moment(this.state.orderDate).isValid() ? moment(this.state.orderDate).format('YYYY-MM-DDTHH:mm:ss') : null,
            receivedDate: moment(this.state.receivedDate).isValid() ? moment(this.state.receivedDate).format('YYYY-MM-DDTHH:mm:ss') : null,
            paidDate: moment(this.state.paidDate).isValid() ? moment(this.state.paidDate).format('YYYY-MM-DDTHH:mm:ss') : null,
            invoiceNumber: this.state.invoiceNumber,
            volume: this.state.volume,
            accountRep: this.state.accountRep,
            accountEmail: this.state.accountEmail,
            phoneNumber: this.state.phoneNumber,
            notes: this.state.notes

        }

        //If it has errors then don't send any data
        if (hasErrors) {
            this.setState({
                hasErrors: true
            })
            return FORM_ERRORS;
        } else {
            this.setState({
                hasErrors: false
            })
            return formData;
        }
    }



    saveInvoice() {

        let formData = this.getInvoiceData(this.state.submitted);
        console.log(formData);
        if (formData !== FORM_ERRORS) {
            //Show the popup once the save is done
            if (this.props.match.params.id) {
                //this is where th save happens
                let saveRequestBody = new FormData();
                if (this.state.file) {
                    saveRequestBody.append('image', this.state.file);
                    saveRequestBody.append('fileName', "Img");
                }
                saveRequestBody.append("price", formData.price)
                moment(this.state.orderDate).isValid() ? saveRequestBody.append("orderDate", formData.orderDate) : console.log('')
                moment(this.state.receivedDate).isValid() ? saveRequestBody.append("receivedDate", formData.receivedDate) : console.log('')
                moment(this.state.paidDate).isValid() ? saveRequestBody.append("paidDate", formData.paidDate) : console.log('')
                saveRequestBody.append("invoiceNumber", formData.invoiceNumber)
                saveRequestBody.append("volume", formData.volume)
                saveRequestBody.append("accountRep", formData.accountRep)
                saveRequestBody.append("accountEmail", formData.accountEmail)
                saveRequestBody.append("phoneNumber", formData.phoneNumber)
                saveRequestBody.append("notes", formData.notes)
                this.props.actions.updateInvoice(this.props.match.params.id, saveRequestBody)
                    .then(() => {
                        let newNoteDetails = this.state.noteDetails;
                        this.setState({
                            noteDetails: newNoteDetails,
                            dataChanged: false
                        });
                    })
                    .then(() => this.togglePopup());
            }
        }
    }

    unsavedDataPrompt(link) {
        if (this.state.dataChanged) {
            this.setState({
                showPrompt: true,
                nextLink: link
            })
        } else {
            this.props.history.push(link);
        }
        this.props.actions.clickHeaderLink(false);
    }

    toggleSubmitPopup() {
        this.setState(state => ({
            showSubmitPopup: !state.showSubmitPopup,
            showPopup: !state.showPopup
        }));
    }

    togglePopup() {
        this.setState(state => ({
            showPopup: !state.showPopup
        }))
        setTimeout(() => { this.props.history.push("/EditInvoice/" + this.props.match.params.id); this.setState({ showPopup: false }) }, 2000);

    }

    toggleCheckboxReq() {
        this.setState(state => ({
            isRequisition: !state.isRequisition
        }));
    }

    handleCompanyChange(e) {
        const selectedCompany = this.state.companies.find(company => company.companyID == e.target.value);
        const key = e.target.name;
        this.setState({
            [key]: e.target.value,

            companyAddress: selectedCompany.companyAddress,
            companyCity: selectedCompany.companyCity,
            companyState: selectedCompany.companyState,
            companyZipCode: selectedCompany.companyZipCode,
            companyFax: selectedCompany.companyFax,
            companyEmail: selectedCompany.companyEmail,
            companyFin: selectedCompany.companyFin,

            contactName: selectedCompany.contactName,
            contactPhone: selectedCompany.contactPhone,
            contactFax: selectedCompany.contactFax,
            contactEmail: selectedCompany.contactEmail
        });
    }
    componentDidMount() {
        //Scroll to top of page since clicking a link far down on the grid will keep the same scroll position
        window.scrollTo(0, 0);
        if (this.props.match.params.id) {
            //Form has an ID so this is an update, set the data for that form
            this.props.actions.getInvoice(this.props.match.params.id)
                .then(() => {
                    if (this.props.form.formGetMessage) {
                        const formData = this.props.form.formGetMessage;
                        console.log(formData.paidDate);
                        this.setState({
                            titleID: formData.titleID,
                            titleName: formData.titleName,
                            publisher: formData.publisher,
                            publisherID: formData.publisherID,
                            price: formData.price,
                            volume: formData.volume,
                            orderDate: formData.orderDate ? new Date(formData.orderDate) : '',
                            receivedDate: formData.receivedDate ? new Date(formData.receivedDate) : '',
                            paidDate: formData.paidDate ? new Date(formData.paidDate) : '',
                            invoiceNumber: formData.invoiceNumber,
                            accountEmail: formData.accountEmail,
                            accountRep: formData.accountRep,
                            phoneNumber: formData.phoneNumber,
                            fileName: formData.fileName,
                            uploadedFileName: formData.fileName,
                            uploadFile: formData.bytes,
                            notes: formData.notes
                        }, function () {

                        });
                    }
                }).then(() => {
                    console.log(this.state.paidDate);
                })
        }
        else {
    }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.form.headerLinkClicked !== this.props.form.headerLinkClicked) {
            if (this.props.form.headerLinkClicked) {
                this.unsavedDataPrompt('/Publishers')
            }
        }
    }

    render() {

        return (
            <React.Fragment>
                <div id="form-container">
                    {this.state.showPopup &&
                        <div tabindex="0" className="popup-container" onBlur={this.blurHandler}>
                            <div tabindex="0" className="popup">
                                <div tabindex="0" className="popup-head">
                                    {this.state.showSubmitPopup ? <span>Form has been submitted</span> : <span>Saved successfully</span>}
                                </div>
                                <div className="popup-body" >
                                    <span>Your Invoice has been saved</span>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="table-header">

                        <h4><Link className="headerlink-Button" type="button" to={'/Publishers/' + this.props.match.params.id}>Publishers</Link>›
                            <Link className="headerlink-Button" type="button" to={'/Publisher/' + this.state.publisherID}>{this.state.publisher}</Link>›
                            <Link className="headerlink-Button" type="button" to={'/EditTitle/' + this.state.titleID}>{this.state.titleName}</Link>›
                            Edit Invoice
                        </h4>
                        {/*<h3>{this.state.titleName}: Invoice</h3>*/}
                        <div>

                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>&lsaquo; Back</button>
                        </div>
                    </div>
                    <div style={{ marginBottom: '22px' }}>
                        <Link id="form-Button" to={'/EditTitle/' + this.state.titleID} type="button" style={{ marginRight: '10px', paddingRight: '15px', paddingLeft: '15px' }}>Title Info</Link>
                        <Link id="form-Button" to={'/Publisher/' + this.state.publisherID} type="button">Publisher Info</Link>
                    </div>
                    <div id="agency-info" className="form-section">
                        <div style={{ margin: '0 auto', width: '95%' }}>
                            <h4>Invoice Information</h4>
                            <div className="multi-row fifth">

                                <div id="half-left">
                                    <label>Price</label>
                                    <input
                                        name="price"
                                        type="text"
                                        value={this.state.price} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Volume</label>
                                    <input
                                        name="volume"
                                        type="text"
                                        value={this.state.volume} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-left">
                                    <label>Order Date</label>
                                    <DatePicker
                                        name="orderDate"
                                        className={`jcar-datepicker ${this.props.infoDateError && 'input-error'}`}
                                        onChange={(val) => this.handleDateChange('orderDate', val)}
                                        selected={this.state.orderDate}
                                        dateFormat= "yyyy/MM/dd"
                                      
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Received Date</label>
                                    <DatePicker
                                        name="receivedDate"
                                        className={`jcar-datepicker ${this.props.infoDateError && 'input-error'}`}
                                        onChange={(val) => this.handleDateChange('receivedDate', val)}
                                        selected={this.state.receivedDate}
                                        dateFormat= "yyyy/MM/dd"
                                    />
                                </div>
                                <div id="half-left">
                                    <label>Paid Date</label>
                                    <DatePicker
                                        name="paidDate"
                                        className={`jcar-datepicker ${this.props.infoDateError && 'input-error'}`}
                                        onChange={(val) => this.handleDateChange('paidDate', val)}
                                        selected={this.state.paidDate}
                                        dateFormat= "yyyy/MM/dd"
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Invoice Number</label>
                                    <input
                                        name="invoiceNumber"
                                        type="text"
                                        value={this.state.invoiceNumber} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-left">
                                    <label>Account Rep</label>
                                    <input
                                        name="accountRep"
                                        type="text"
                                        value={this.state.accountRep} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Account Email</label>
                                    <input
                                        name="accountEmail"
                                        type="text"
                                        value={this.state.accountEmail} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-left">
                                    <label>Phone Number</label>
                                    <input
                                        name="phoneNumber"
                                        type="text"
                                        value={this.state.phoneNumber} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Upload a Document</label>
                                    <button type="button" className="button small-button file-button">
                                        <input type="file" accept=".pdf" onChange={this.handleFileChange} />
                                    </button>
                                </div>
                                <div >
                                    <label>Attachment</label>
                                    <button className="button-download" onClick={this.downloadFile}> {this.state.uploadFile ? this.state.uploadedFileName : "Upload a Document"}  </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="note-info" className="form-section">
                        <div style={{ margin: '0 auto', width: '95%' }}>
                            <h4>Notes</h4>
                            <div id="noteDetail">
                                <textarea name="notes" onChange={this.handleChange} value={this.state.notes} />
                            </div>
                        </div>
                    </div>
                    <div className="submit-section">
                        <button onClick={this.saveInvoice} type="button">Save</button>
                    </div>

                    <div className="button-menu">
                        <div style={{ float: 'right' }}>
                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>&lsaquo; Back</button>
                        </div>
                        {this.props.match.params.id &&
                            <div style={{ float: 'left' }}>
                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>Delete Publisher <span className="icon destroy"></span></button>
                            </div>}
                        {this.state.hasErrors && <span className="error-text">Required fields are missing</span>}
                        <ReactToPrint
                            copyStyles={false}
                            trigger={() => <button type="button" id={!this.state.isRequisition ? 'hidden' : undefined}>Print</button>}
                            content={() => this.componentRef}
                            pageStyle={{ size: 'auto', margin: '0px' }}
                        />
                    </div>
                    <ContractDetails
                        {...this.state}
                        ref={el => (this.componentRef = el)}
                        agencies={this.props.form.dlasContacts}
                        period={this.state.companyCity} />
                </div>
            </React.Fragment>
        )
    }
}




export default connect(
    (state) => {
        const { form } = state;
        return {
            form
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, formActionCreators), dispatch)
        }
    }
)(InvoiceFormContainer);
