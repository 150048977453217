import React from 'react';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import * as sort from 'sortabular'
import orderBy from 'lodash/orderBy';
import * as resolve from 'table-resolver';
import { Link } from "react-router-dom";
import * as Table from 'reactabular-table';
import * as select from 'selectabular';
import { compose } from 'redux';
import { gridActionCreators } from '../scripts/store/Grid';
import { authActionCreators } from '../scripts/store/Login';
import '../stylesheets/grid.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"
//import 'bootstrap/dist/css/bootstrap.min.css';
import firstPicture from '../images/1resize.jpg'
import libraryPicture from '../images/library.jpg'
import servicesPicture from '../images/meta-chart.jpg'
import ourmissionpicture from '../images/ourmission.jpg'
const items = [
    {
        id: 0,
        name: 'Cobol'
    },
    {
        id: 1,
        name: 'JavaScript'
    },
    {
        id: 2,
        name: 'Basic'
    },
    {
        id: 3,
        name: 'PHP'
    },
    {
        id: 4,
        name: 'Java'
    }
]
class Resources extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            selectedRow: '',
            rows: [],
            filteredRows: [],
            selectedRows: [],
            selectAll: false,
            filterOptions: [],
            publisherFilter: 'all',
            contractTypeFilter: 'all',
            prefix: '',
            suggestion: ''
        };
        this.filterOptions = this.filterOptions.bind(this);
        this.selectCallback = this.selectCallback.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnHover = this.handleOnHover.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.formatResult = this.formatResult.bind(this);
    }

    componentDidMount() {

    }
    handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    handleOnHover = (result) => {
        // the item hovered
        console.log(result)
    }

    handleOnSelect = (item) => {
        // the item selected
        console.log(item)
    }

    handleOnFocus = () => {
        console.log('Focused')
    }

    formatResult = (item) => {
        return item
        // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    }


    onChange() { }
    filterOptions(statename, value) {
        let filter = value.value;
        if (statename == 'selectedYear') {
            this.setState(
                {
                    yearFilter: filter
                }, function () { this.updateFilter(); }
            );
        } else if (statename == 'selectedContractType') {
            this.setState(
                {
                    contractTypeFilter: filter
                }, function () { this.updateFilter(); }
            );
        }

    }

    handleFilterChange(target) {
        let filter = target.value;
        console.log(filter);
        console.log(target.name);
        if (target.name === 'publisher-Filter') {
            this.setState(
                {
                    publisherFilter: filter
                }, function () { this.updateFilter(); }
            );
        }
    }

    updateFilter() {
        const { publisherFilter } = this.state;
        //Set the users prefrences
        localStorage.setItem('filterpref', JSON.stringify({
            publisherFilter: publisherFilter
        }));
        //If both the filters are set to all then show all the rows
        if (publisherFilter == 'all') {
            this.setState(
                { filteredRows: this.state.rows }
            );
        } else {
            //Uses select library
            //Creates a variable that is used to return the list of rows that satisfy the condition
            const filter = row => {
                if ([row.publisher, 'all'].indexOf(publisherFilter) != -1) return row
            }
            const { rows, selectedRows: result } = select.rows(filter)(this.state.rows);

            //Sets filteredRows to the result. This updates what rows the user can see
            this.setState(
                { filteredRows: result }
            );
        }
    }

    //updateFilter() {
    //    const { yearFilter, contractTypeFilter } = this.state;

    //    if (yearFilter == 'all' && contractTypeFilter == 'all') {
    //        this.setState(
    //            { filteredRows: this.state.rows }
    //        );
    //    } else {
    //        const filter = row => {
    //            if ([row.year, 'all'].indexOf(yearFilter) != -1 && [row.contractTypeID, 'all'].indexOf(contractTypeFilter) != -1) return row
    //        }
    //        const { rows, selectedRows: result } = select.rows(filter)(this.state.rows);

    //        //Sets filteredRows to the result. This updates what rows the user can see
    //        this.setState(
    //            { filteredRows: result }
    //        );
    //    }
    //}

    selectCallback(state, value) {
        this.setState({
            [state]: value
        }, () => {
            this.getTableData();
        });
    }


    deleteRow(row) {
        console.log("made it to confirmdelete")
        console.log(row)
        console.log(this.state.selectedRow)
        this.setState({
            selectedRow: row
        }, function () { this.togglePopup(); });
    }
    confirmDelete() {
        console.log("made it to confirmdelete")
        console.log(this.state.selectedRow)
        this.props.actions.deleteReport(this.state.selectedRow.contractID)
            .then(() => {
                const selectedRow = this.state.selectedRow;
                const rows = [...this.state.rows];
                const filteredRows = [...this.state.filteredRows];

                const filteredRowIndex = filteredRows.findIndex(row => selectedRow.contractID === row.contractID);
                const rowIndex = rows.findIndex(row => selectedRow.contractID === row.contractID);
                rows.splice(rowIndex, 1);
                filteredRows.splice(filteredRowIndex, 1);
                this.setState({
                    rows: rows,
                    filteredRows: filteredRows,
                    selectedRow: ''
                });
            })
            .then(() => this.togglePopup());
    }

    togglePopup() {
        console.log("did we make it here")
        this.setState(state => ({
            showPopup: !state.showPopup
        }));
    }

    render() {
        return (
            <div>
                <div className="table-header">
                </div>
                <div className="grid-choice">
                    <h1>Virginia Legislative Research Sources:</h1>
                    <p><strong>Legislation</strong></p>
                        <p><i>House and Senate Bills and Resolutions. </i>Daily during session; bound annually.</p>
                            <dir>
                                <p>All legislation introduced (both passed and failed) in each house. Includes printed amendments.</p>
                            </dir>
                        <p><i>Fiscal Impact Statements</i>. Daily during session; bound annually.</p>
                            <dir>
                                <p>Analysis of fiscal implications of proposed legislation. Prepared by various state agencies.</p>
                            </dir>
                    <p><strong>Law</strong></p>
                                    <p><i>Code of Virginia 1950</i>.
                                        Periodic new volumes, updated annually by pocket parts.</p>
                                        <dir>
                                            <p>Codification of the general and permanent laws of the Commonwealth. Sections are arranged numerically within titles.</p>
                                        </dir>
                    <p><i>Acts of Assembly. </i>Annual.</p>
                                        <dir>
                                             <p>Contains enactments of the Virginia General Assembly for each session. Multi-year subject indexes available.</p>
                                        </dir>
                    <p><strong>Finding Aids</strong></p>
                                                    <p><i>Final Cumulative Index of Bills, Joint Resolutions, Resolutions and Documents</i>. Annual.</p>

                                                        <dir>
                                                            <p>Provides subject access
                                                                to all legislation considered. Also indexes legislation by patron
                                                                name and includes bill status history. Abbreviated index published
                                                                semi-weekly during session. </p>  
                                                        </dir>
                                                            <p><i>Session Summary</i>. Annual.</p>
                                                                <dir>
                                                                    <p>Subject access to summaries of all legislation considered by the General Assembly with disposition
                                                                        (i.e., passed, failed or carried over) noted. 
                                                                    </p>
                                                                </dir>
                                                             <p><i>Digest of the Acts of the General Assembly.</i> Annual.</p>
                                                                        <dir>
                                                                            <p>Overview of enacted legislation,
                                                        with title and section paralleling that of the <i>Code of Virginia.</i></p> </dir>
                    <p><strong>Roll Call Votes</strong></p>
                        <p><i>House and Senate Calendars.</i>Daily during session; bound annually.</p>
                                                    <dir>
                                                        <p>Account of legislation pending in each house and meeting notices. Furnishes votes for bills reported out of committee.  </p>
                                                    </dir>
                                    <p><i>House and Senate Journals.</i>Annual.</p>
                                                    <dir>
                                                         <p>Detailing of floor activities, including roll call votes, committee reports, and conference reports.</p>
                                                    </dir>
                   <p><strong>Legislative Study Reports</strong></p>
                                          <p><i>House and Senate Documents.</i> Issued as printed throughout the year; bound annually.</p>
                                                <dir>
                                                            <p>Reports requested by and submitted to the General Assembly.   </p>
                                                </dir>
         
                                                 <p><i>Topical Studies by the General Assembly of Virginia.</i> Indexes available for years 1970 - 1986 and 1987 - 1997.</p>
                                                 <dir>
                                                            <p>Subject index to the legislative study reports (i.e., House and Senate Documents). Searchable database
                                                              also available via the internet <strong><a href="http://leg2.state.va.us/dls/h&sdocs.nsf/Published%2Bby%2BYear?OpenForm&StartKey=2015&ExpandView">here</a></strong>. 
                                                             </p>
                                                 </dir>

                   <p><strong>General Assembly Information</strong></p>
                                               <p><i>Manual of the Senate and House of Delegates.</i> Biennial.</p>
                                                    <dir>
                                                            <p>Contains member biographies, rules of both houses, constitution, general information about each house.</p>
                                                    </dir>
                                                         <p><i>The General Assembly of Virginia</i>. Approximately every 20 years.</p>
                                                    <dir>
                                                         <p>Historical reference for member biographies, legislative studies, committee rosters, etc.</p>
                                                    </dir>
                   <p><strong>Topical Studies of the General Assembly of Virginia</strong></p>
                                                        <ul>
                                                            <li><a href="TOPSTUDIES20042007.pdf" target="_blank">2004-2007</a>
                                                                (pdf)</li>
                                                            <li><a href="TOPSTUDIES20012003.pdf" target="_blank">2001-2003</a>
                                                                (pdf) </li>
                                                            <li><a href="TOPSTU19982000.pdf" target="_blank">1998-2000</a>
                                                                (pdf) </li>
                                                            <li><a href="TOPSTU19871997.pdf" target="_blank">1987-1997</a>
                                                                (pdf) </li>
                                                            <li><a href="TOPSTU19701986.pdf" target="_blank">1970-1986</a>
                                                                (pdf)</li>
                                                        </ul>
                                                                                                                            
                    <p><strong>Electronic Information</strong></p>
                                             <p>Legislative Information System (LIS): http://lis.virginia.gov </p>
                                                        <dir>
                                                            <p>Online access to complete
                                                                bill status, searchable text of legislation, patron history, and
                                                                roll call votes (committee and floor) from 1994 - present; also
                                                                provides word-searchable access to the <i>Code of Virginia</i>,
                                                                <i>Virginia Administrative Code, </i>and list of legislative studies.
                                                            </p>
                                                        </dir>
                                                            <p>Legislative Reference Center:
                                                                <a href="https://reference.dls.virginia.gov">https://reference.dls.virginia.gov</a>
                                                            </p>
                                                            <dir>
                                                                    <p>Includes information on legislative history in Virginia, and offers a comprehensive set of internet research links.</p>
                                                            </dir>
                                                                                                                                    
                                                        <p><strong>Information Contacts</strong></p>
                                                        <p><b>Legislative Reference Center</b><br />
                                                        Division of Legislative Services<br />
                                                        201 N. 9th Street<br />
                                                        General Assembly Building, 4th floor<br />
                                                        Richmond, Virginia 23219<br />
                                                        (804) 698-1888<br />
                                                        email contact: <a href="mailto:ljones@dls.virginia.gov">ljones@dls.virginia.gov</a><br />
                        <a href="https://reference.dls.virginia.gov">https://reference.dls.virginia.gov</a> </p>
                                                        <p><b>Legislative Information Office<br /></b>Capitol, First floor<br />
                                                        Capital Square<br />
                                                        Richmond, Virginia 23219<br />
                                                            (804) 698-1500</p>
                                                        <p><b>Senate Information</b><br />Capitol, First floor<br />
                                                        Senate Room 2<br />
                                                        Capital Square<br />
                                                        Richmond, Virginia 23219<br />
                                                            (804) 698-7410</p>
                                                        <p><b>Library of Virginia<br /></b>9<sup>th</sup> and Broad Streets<br />
                                                                    Richmond, Virginia 23219<br />
                                                                </p>
                </div>
                    <div class="push"></div>
                </div>
                )
    }
}








                function getUrlParameter(name, params) {
                    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                var results = regex.exec(params);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

                export default connect(
    (state) => {
        const {grid, login} = state;
                return {
                    grid,
                    login
                }
    },
    (dispatch) => {
        return {
                    actions: bindActionCreators(Object.assign({ }, gridActionCreators, authActionCreators), dispatch)
        }
    }
)(Resources)                          