import React from 'react';
import Header from './Header';
import Footer from './Footer';
export default props => (
    <div style={{ height: '100%' }}>
        <Header />
        <main>
            <section>
                <div className="full">
                    {props.children}
                </div>
            </section>
        </main>
        <Footer />
    </div>
);
