import React from 'react';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import * as sort from 'sortabular'
import orderBy from 'lodash/orderBy';
import * as resolve from 'table-resolver';
import { Link } from "react-router-dom";
import * as Table from 'reactabular-table';
import * as select from 'selectabular';
import { compose } from 'redux';
import { gridActionCreators } from '../scripts/store/Grid';
import { authActionCreators } from '../scripts/store/Login';
import '../stylesheets/grid.css';
import InvoiceListToolbarComponent from './toolbars/InvoiceList-toolbar';

class InvoiceForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            selectedRow: '',
            rows: [],
            filteredRows: [],
            selectedRows: [],
            selectAll: false,
            filterOptions: [],
            publisherFilter: 'all',
            contractTypeFilter: 'all'
        };
        this.filterOptions = this.filterOptions.bind(this);
        this.selectCallback = this.selectCallback.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        this.props.actions.validateUser(this.props.login.userProfile.email, this.props.login.userProfile.hash)
        this.props.actions.getInvoices()
            .then(() => {
                let rows = this.props.grid.rowList;
                //Add the index (for finding the row) and the checked property (for toggling the checkbox)
                rows.forEach((row, index) => {
                    row.checked = false;
                    row.id = index;
                });

                this.setState(state => (
                    {
                        rows: rows,
                        filteredRows: rows
                    }
                ));
            })
    }
    filterOptions(statename, value) {
        let filter = value.value;
        if (statename == 'selectedYear') {
            this.setState(
                {
                    yearFilter: filter
                }, function () { this.updateFilter(); }
            );
        } else if (statename == 'selectedContractType') {
            this.setState(
                {
                    contractTypeFilter: filter
                }, function () { this.updateFilter(); }
            );
        }

    }

    handleFilterChange(target) {
        let filter = target.value;
        console.log(filter);
        console.log(target.name);
        if (target.name === 'publisher-Filter') {
            this.setState(
                {
                    publisherFilter: filter
                }, function () { this.updateFilter(); }
            );
        } 
    }

    updateFilter() {
        const { publisherFilter} = this.state;
        //Set the users prefrences
        localStorage.setItem('filterpref', JSON.stringify({
            publisherFilter: publisherFilter
        }));
        //If both the filters are set to all then show all the rows
        if (publisherFilter == 'all') {
            this.setState(
                { filteredRows: this.state.rows }
            );
        } else {
            //Uses select library
            //Creates a variable that is used to return the list of rows that satisfy the condition
            const filter = row => {
                if ([row.publisher, 'all'].indexOf(publisherFilter) != -1) return row
            }
            const { rows, selectedRows: result } = select.rows(filter)(this.state.rows);
            
            //Sets filteredRows to the result. This updates what rows the user can see
            this.setState(
                { filteredRows: result }
            );
        }
    }

    //updateFilter() {
    //    const { yearFilter, contractTypeFilter } = this.state;

    //    if (yearFilter == 'all' && contractTypeFilter == 'all') {
    //        this.setState(
    //            { filteredRows: this.state.rows }
    //        );
    //    } else {
    //        const filter = row => {
    //            if ([row.year, 'all'].indexOf(yearFilter) != -1 && [row.contractTypeID, 'all'].indexOf(contractTypeFilter) != -1) return row
    //        }
    //        const { rows, selectedRows: result } = select.rows(filter)(this.state.rows);

    //        //Sets filteredRows to the result. This updates what rows the user can see
    //        this.setState(
    //            { filteredRows: result }
    //        );
    //    }
    //}

    selectCallback(state, value) {
        this.setState({
            [state]: value
        }, () => {
            this.getTableData();
        });
    }


    deleteRow(row) {
        console.log("made it to confirmdelete")
        console.log(row)
        console.log(this.state.selectedRow)
        this.setState({
            selectedRow: row
        }, function () { this.togglePopup(); });
    }
    confirmDelete() {
        console.log("made it to confirmdelete")
        console.log(this.state.selectedRow)
        this.props.actions.deleteReport(this.state.selectedRow.contractID)
            .then(() => {
                const selectedRow = this.state.selectedRow;
                const rows = [...this.state.rows];
                const filteredRows = [...this.state.filteredRows];

                const filteredRowIndex = filteredRows.findIndex(row => selectedRow.contractID === row.contractID);
                const rowIndex = rows.findIndex(row => selectedRow.contractID === row.contractID);
                rows.splice(rowIndex, 1);
                filteredRows.splice(filteredRowIndex, 1);
                this.setState({
                    rows: rows,
                    filteredRows: filteredRows,
                    selectedRow: ''
                });
            })
            .then(() => this.togglePopup());
    }

    togglePopup() {
        console.log("did we make it here")
        this.setState(state => ({
            showPopup: !state.showPopup
        }));
    }

    render() {
        return (
            <div>
                {this.state.showPopup &&
                    <div className="popup-container">
                        <div className="popup">
                            <div className="popup-head">
                                <span>Are you sure you want to delete this contract?</span>
                            </div>
                            <div className="popup-body">
                                <span>Deleting this cannot be undone</span>
                            </div>
                            <div className="button-menu">
                                <button onClick={this.confirmDelete} className="alternate-button">Yes, delete</button>
                                <button onClick={this.togglePopup} className="button-link">No</button>
                            </div>
                        </div>
                    </div>
                }
                <div className="table-header">
                    <h2>Invoices List</h2>
                </div>
                <div className="table-filter">
                    <InvoiceListToolbarComponent rows={this.state.filteredRows}
                        allRows={this.state.rows}
                        onFilterChange={this.handleFilterChange}
                        publisherFilter={this.state.publisherFilter}/>
                </div>

                <div className="table-content">
                    <Invoices
                        rows={this.state.filteredRows}
                        deleteRow={this.deleteRow}
                        selectedRows={this.state.selectedRows}
                        onSelectChange={this.handleSelectChange}
                        isAdministrator={this.props.login.isAdministrator}
                        selectAll={this.state.selectAll} />

                </div>
            </div>
        )
    }
}



class Invoices extends React.Component {

    constructor(props) {
        super(props);
        const actionButtons = ((rowIndex) => {
            return (
                <div className="no-gap-grid three">
                    <Link title="Edit invoice" to={'/EditInvoice/' + this.props.rows[rowIndex].invoiceID} className="icon edit"></Link>
                    <span title="Delete invoice" onClick={() => { this.props.deleteRow(this.props.rows[rowIndex]) }} className="icon destroy"></span>
                </div>
            );
        })
        
        this.state = {
            columns: [
                {
                    property: 'invoiceNumber',
                    props: {
                        style: {
                            minWidth: '30em'
                        }
                    },
                    header: {
                        label: 'invoiceNumber',

                        formatters: [
                            () => (
                                <span>Invoice Num</span>
                            )
                        ]

                    },
                    cell: {
                        formatters: [
                            (value, { rowData }) => (
                                <span> Invoice #
                                    {rowData.invoiceNumber}
                                </span>
                            )
                        ]
                    }
                },
                {
                    property: 'paidDate',
                    props: {
                        style: {
                            Width: '7em'
                        }
                    },
                    header: {
                        label: 'paidDate',
                        formatters: [
                            () => (
                                <span>Paid Date</span>
                            )
                        ]
                    },
                    cell: {
                        formatters: [
                            (value, { rowData }) => (
                                <span>
                                    {rowData.paidDate}
                                </span>
                            )
                        ]
                    }
                },
                {
                    property: 'price',
                    props: {
                        style: {
                            Width: '7em'
                        }
                    },
                    header: {
                        label: 'price',
                        formatters: [
                            () => (
                                <span>Price</span>
                            )
                        ]
                    }
                },
                {
                    property: 'notes',
                    props: {
                        style: {
                            minWidth: '348px'
                        }
                    },
                    header: {
                        label: 'Notes',
                        formatters: [
                            () => (
                                <span>Notes</span>
                            )
                        ]
                    }
                },
                {
                    property: 'actions',
                    props: {
                        style: {
                            width: '54px'
                        }
                    },
                    header: {
                        label: 'Actions'
                    },
                    cell: {
                        formatters: [
                            (value, { rowData, rowIndex }) => (
                                <div>
                                    {actionButtons(rowIndex)}
                                </div>
                            )
                        ]
                    }
                }
            ]
        }
    }


    render() {
        const { sortingColumns, columns } = this.state;
        const { rows } = this.props;
        const BodyWrapper = props => <tbody {...props} />;
        BodyWrapper.shouldComponentUpdate = true;

        const resolvedColumns = resolve.columnChildren({ columns });
        const sortedRows = compose(
            sort.sorter({
                columns: resolvedColumns,
                sortingColumns,
                sort: orderBy,
                strategy: sort.strategies.byProperty
            }),
            resolve.resolve({
                columns: resolvedColumns,
                method: resolve.nested
            })
        )(rows);

        return (
            <div>
                {/* this anchor is used to set the filename and the file to be downloaded when the user click the pdf icon */}
                <a target='_blank' ref={this.pdfRef} />
                <Table.Provider columns={resolvedColumns}
                    renderers={{
                        body: {
                            wrapper: BodyWrapper
                        }
                    }}>
                    <Table.Header
                        headerRows={resolve.headerRows({ columns })}
                    />

                    <Table.Body
                        rows={sortedRows}
                        rowKey="exemptionReportID"
                    />
                </Table.Provider>
            </div>
        );
    }
}



function getUrlParameter(name, params) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(params);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default connect(
    (state) => {
        const { grid, login } = state;
        return {
            grid,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, gridActionCreators, authActionCreators), dispatch)
        }
    }
)(InvoiceForm);