import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../scripts/store/Login';
import '../stylesheets/Login.css';

class LoginForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            isSubmitting: '',
            ErrorMessage: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({
            [key]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const creds = {
            username: this.state.username,
            password: this.state.password
        }
        this.props.actions.loginUser(creds)
            .then(() => {
                if (this.props.login.isAuthenticated) {
                    this.props.history.push('/Publishers');
                }
            })
    }

    render() {
        const { username, password, isSubmitting, ErrorMessage } = this.state
        return (
            <div className="login-container">
                <h2>Library Acquisition System</h2>
               
                <div className="spacer"></div>
                <span>Please enter your email address and Password to log in.</span>
                <div className="login-form">
                    <form onSubmit={this.handleSubmit}>
                        <input
                            id="username"
                            name="username"
                            autoFocus="true"
                            type="text"
                            placeholder="User ID"
                            onChange={this.handleChange}
                            value={username}
                        />
                        <input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={this.handleChange}
                            value={password}
                        />
                        <button type="submit" disabled={isSubmitting}>
                            Log In
                        </button>
                    </form>
                    {this.props.login.errorMessage &&
                        <div className="error-box">
                            <span>{this.props.login.errorMessage}</span>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const Login = connect(
    (state) => {
        const { login } = state;
        return {
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators), dispatch)
        }
    }
)(LoginForm)


export default Login;