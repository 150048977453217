import React, { useState } from "react";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../scripts/store/Login';
import { formActionCreators } from '../scripts/store/Form';
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
const pages = ["Products", "Services", "ABoutUs", "ContactUs"];
const DrawerComp = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
        {props.login.isAuthenticated && 
            <ListItemButton>
              <ListItemIcon>
                <ListItemText><Link to="/Publishers">Publishers</Link></ListItemText>
              </ListItemIcon>
            </ListItemButton>
            }
              {props.login.isAuthenticated && 
            <ListItemButton>
              <ListItemIcon>              
                <ListItemText>  <Link to="/Titles" >Titles</Link></ListItemText>
              </ListItemIcon>
            </ListItemButton>
        }
             {props.login.isAuthenticated && 
            <ListItemButton>
              <ListItemIcon>              
                <ListItemText>{props.login.isAuthenticated && <Link to="/Invoices" >Invoices</Link>}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            }
            {props.login.isAuthenticated && 
            <ListItemButton>
              <ListItemIcon>              
                <ListItemText>{props.login.isAuthenticated && <Link to="/Reports" >Reports</Link>}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            }
            <ListItemButton>
              <ListItemIcon>              
                <ListItemText>{props.login.isAuthenticated && <button onClick={props.actions.logoutUser}>Log Out</button>}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
       
            <ListItemButton>
              <ListItemIcon>              
              <ListItemText>  {!props.login.isAuthenticated && <Link to="/History">Legislative History</Link>}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>              
              <ListItemText>{!props.login.isAuthenticated && <Link to="/Resources">Legislative Resources</Link>}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>              
              <ListItemText> {!props.login.isAuthenticated && <Link to="/Collections">Collections</Link>}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>              
                <ListItemText> {!props.login.isAuthenticated && <Link to="/About">About</Link>}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>              
                <ListItemText> {!props.login.isAuthenticated && <Link to="/Login">Login</Link>}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
        
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
}


export default withRouter(connect(
    (state) => {
        const { login } = state;
        return {
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, formActionCreators), dispatch)
        }
    }
)(DrawerComp))