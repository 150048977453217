import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { gridActionCreators } from '../scripts/store/Grid';
import { formActionCreators } from '../scripts/store/Form';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactToPrint from 'react-to-print';
import ContractDetails from './ContractDetails';
import "react-datepicker/dist/react-datepicker.css";
import '../stylesheets/form.css';
import { authActionCreators } from '../scripts/store/Login';
import ReactTable from 'react-table-6'
import "react-table-6/react-table.css";
import { CSVLink, CSVDownload } from 'react-csv';
import jsPDF from "jspdf";
import "jspdf-autotable";

const FORM_ERRORS = 1;



class ReportContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dlasContacts: [],
            companyCity: '',
            people: [
                { name: "Keanu Reeves", profession: "Actor" },
                { name: "Lionel Messi", profession: "Football Player" },
                { name: "Cristiano Ronaldo", profession: "Football Player" },
                { name: "Jack Nicklaus", profession: "Golf Player" },
            ],
            columns: [{
                Header: 'Number of acquisitions',
                accessor: 'numberofInvoices'

            }, {
                Header: 'Total spent',
                accessor: 'total',
                style: {
                    textAlign: 'center'
                },
                // provide custom function to format props 
                Cell: (row) => {

                    const sale_status = "$" + Number.parseFloat(row.original.total).toFixed(2);
                    console.log(sale_status)
                    return sale_status;

                }

            }],
            year: null,
            dataChanged: false,
            id: this.props.match.params.id,
            submitted: false,
            fiscsubmitted: false,
            showPopup: false,
            showSubmitPopup: false,

            startDate: '',
            endDate: '',
            fiscalstartDate: '',
            fiscalendDate: '',
            rows: [],
            publisherData: [],
            titleData: [],
            selectedRows: [],
            selectAll: false,
            filterOptions: [],
            publisherFilter: 'all',
            selectedPublisherID: null,
            Titles: [],
            Publishers: [],
            total: null,
            required: false 
        };


        this.handleChange = this.handleChange.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.toggleSubmitPopup = this.toggleSubmitPopup.bind(this);
        this.blurHandler = this.blurHandler.bind(this);

        this.handlePublisherChange = this.handlePublisherChange.bind(this);
        this.getPublisherReport = this.getPublisherReport.bind(this);
        this.getFiscalReport = this.getFiscalReport.bind(this);
        this.renderSubComponent = this.renderSubComponent.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
    }
    //download(event) {
    //    const currentRecords = this.reactTable.getResolvedState().sortedData;
    //    var data_to_download = []
    //    for (var index = 0; index < currentRecords.length; index++) {
    //        let record_to_download = {}
    //        for (var colIndex = 0; colIndex < columns.length; colIndex++) {
    //            record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
    //        }
    //        data_to_download.push(record_to_download)
    //    }
    //    this.setState({ dataToDownload: data_to_download }, () => {
    //        // click the CSVLink component to trigger the CSV download
    //        this.csvLink.link.click()
    //    })
    //}
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "My Awesome Report";
        const headers = [["Title", "Number of acquisitions per title"]];

        const data = this.state.Titles.map(elt => [elt.invoicebyTitlesNumber, elt.titleName]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    } 

    handleChange(e) {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({
            [key]: value
        });
    }

    blurHandler() {
        this.setState({
            showPopup: false
        })
    }

    getPublisherReport(isSubmitting) {
        if (!this.state.selectedPublisherID) {
            this.setState({
                required: true
            })
        } else {
        let formData = {};
        formData = {
            ...formData,
            publisherID: parseInt(this.state.selectedPublisherID),
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }
        let hasErrors = 0;
        this.props.actions.getPublisherReport(formData)
            .then(() => {

                const formData = this.props.grid.rowList;
                console.log(formData)
                this.state.publisherData.push(formData);
                console.log(formData)
                this.setState({
                    numberofInvoices: formData.numberofInvoices,
                    total: formData.total,
                    Titles: formData.titles,
                    submitted: true,
                    fiscsubmitted: false,
                    required: false

                }, function () {

                });
                console.log(this.state.Titles)
            })
        //If it has errors then don't send any data
        if (hasErrors) {
            this.setState({
                hasErrors: true
            })
            return FORM_ERRORS;
        } else {
            this.setState({
                hasErrors: false
            })
            return formData;
            }
        }
    }

    getFiscalReport(isSubmitting) {
        let formData = {};
        formData = {
            ...formData,
            publisherID: 0,
            startDate: this.state.fiscalstartDate,
            endDate: this.state.fiscalendDate
        }
        let hasErrors = 0;
        console.log(formData);
        this.props.actions.getFiscalReport(formData)
            .then(() => {

                const formData = this.props.grid.rowList;
                this.setState({
                    titleData: this.state.titleData.slice(0, 0)
                })
                this.state.titleData.push(formData);
            
                this.setState({
                 
                    numberofInvoices: formData.numberofInvoices,
                    total: formData.total,
                    Publishers: formData.publishers,
                    fiscsubmitted: true,
                    submitted: false

                }, function () {

                });
                console.log(this.state.Publishers)
            })
        //If it has errors then don't send any data
        if (hasErrors) {
            this.setState({
                hasErrors: true
            })
            return FORM_ERRORS;
        } else {
            this.setState({
                hasErrors: false
            })
            return formData;
        }
    }

    toggleSubmitPopup() {
        this.setState(state => ({
            showSubmitPopup: !state.showSubmitPopup,
            showPopup: !state.showPopup
        }));
    }

    togglePopup() {
        this.setState(state => ({
            showPopup: !state.showPopup
        }))
        setTimeout(() => {
            this.setState(state => ({
                showPopup: !state.showPopup
            })) }, 2000);

    }

    toggleCheckboxReq() {
        this.setState(state => ({
            isRequisition: !state.isRequisition
        }));
    }

    handlePublisherChange(e) {
        this.setState({
            selectedPublisherID: e.target.value
        });
    }

    componentDidMount() {
        //Scroll to top of page since clicking a link far down on the grid will keep the same scroll position
        window.scrollTo(0, 0);

        //Form has an ID so this is an update, set the data for that form
        this.props.actions.getRows()
            .then(() => {
                let rows = this.props.grid.rowList;
                //Add the index (for finding the row) and the checked property (for toggling the checkbox)
                rows.forEach((row, index) => {
                    row.checked = false;
                    row.id = index;
                });

                this.setState(state => (
                    {
                        rows: rows,
                        filteredRows: rows
                    }
                ));
            })

    }

    renderSubComponent = ({ original }) => {
        console.log("werwer");
        console.log(original);
        const titlesColumns = [{

            accessor: 'titleName'

        }, {

            accessor: 'numberofInvoices',
            style: {
                textAlign: 'center'
            },
            // provide custom function to format props 
            Cell: (row) => {

                const numberofInvoices = "Number of Invoices : " + row.original.numberofInvoices;
                return numberofInvoices;
            }
        },
        {
            accessor: 'price',
            style: {
                textAlign: 'center'
            },
            // provide custom function to format props 
            Cell: (row) => {

                const sale_status = "price : $" + row.original.price;
                return sale_status;
            }
        }]
        return (

            <React.Fragment>
                <div className="subTable">
                    <ReactTable
                        data={original.titles}
                        columns={titlesColumns}
                        defaultPageSize={(original.titles.length > 14) ? 15 : original.titles.length}
                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    />
                </div>
            </React.Fragment>

        );
    };

    render() {
        
        const titleColumns = [{
            Header: 'Title',
            accessor: 'titleName'

        }, {
            Header: 'Number of acquisitions per title',
            accessor: 'invoicebyTitlesNumber',
            style: {
                textAlign: 'center'
            },
            // provide custom function to format props 


        }]

        const fiscColumns = [{
            Header: 'Number of acquisitions ',
            accessor: 'numberofInvoices'

        }, {
            Header: 'Total spent',
            accessor: 'total',
            style: {
                textAlign: 'center'
            },
            // provide custom function to format props 
            Cell: (row) => {

                const sale_status = "$" + Number.parseFloat(row.original.total).toFixed(2);
                console.log(sale_status)
                return sale_status;

            }

        }]
        const fiscPublisherColumns = [{
            Header: 'Publisher',
            accessor: 'publisher',
            style: {
                fontWeight: 'bold'
            },

        }, {
            Header: 'Total spent',
            accessor: 'total',
            style: {
                textAlign: 'center'
            },
            // provide custom function to format props 
            Cell: (row) => {
                if (row.original.total != undefined) {
                    const total = "$" + Number.parseFloat(row.original.total).toFixed(2);
                    return total;
                } else {
                    return "$0";
                }
            }
        }]

        return (
            <React.Fragment>
                <div id="form-container">
                    {this.state.showPopup &&
                        <div className="popup-container">
                            <div className="popup">
                                <div className="popup-head">
                                    <span>Publisher Field required</span>
                                </div>
                                <div className="popup-body">
                                    <span>Please select a publisher to run a publisher report</span>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="table-header">

                        <h4>Reports</h4>
                        <h3>{this.state.period}</h3>
                        <div>
                            <button className="back-link" >&lsaquo; Back</button>
                        </div>
                    </div>

                    <div id="agency-info" className="form-section">
                        <div style={{ margin: '0 auto', width: '95%', paddingBottom: '5px' }}>
                            <h4> Create a Publisher Report</h4>
                            <div className="multi-row fifth">
                                {/*<CSVLink data={csvData} >Download me</CSVLink>*/}
                                {/*  <button onClick={() => this.exportPDF()}>Generate Report</button>*/}
                                <ReactToPrint
                                    copyStyles={false}
                                    trigger={() => <button type="button">Print</button>}
                                    content={() => this.componentRef}
                                    pageStyle={{ size: 'auto', margin: '0px' }}
                                />
                                <ContractDetails
                                    {...this.state}
                                    ref={el => (this.componentRef = el)}
                                    agencies={this.state.dlasContacts}
                                    period={this.state.companyCity} />
                                <div>
                                    <label>Publisher</label>
                                    {this.state.required &&
                                        <i className="text-danger">*required</i>
                                        }
                                    <select name="publisher-Filter" value={this.state.publisher} onChange={this.handlePublisherChange} >
                                        <option value="all">Type or Select a Publisher</option>
                                        {this.state.rows.map((publisher) => <option value={publisher.publisherID}>{publisher.publisher}</option>)}
                                    </select>
                                </div>
                                <div id="half-left">
                                    <label>Start Date</label>
                                    <input
                                        name="startDate"
                                        type="date"
                                        value={this.state.startDate} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>End Date</label>
                                    <input
                                        name="endDate"
                                        type="date"
                                        value={this.state.endDate} onChange={this.handleChange}
                                    />
                                </div>
                                <div className="submit-section">
                                    <button onClick={this.getPublisherReport} type="button">Create Publisher Report</button>
                                </div>

                            </div>
                            <h4> Create a Fiscal Report</h4>
                            <div className="multi-row fifth">

                                <div>
                                    <label>All Publishers</label>
                                    <div>
                                        <label class="checkbox-label">
                                            <input type="checkbox" className="allPubs" style={{ width: "20px" }} checked="true" />
                                            <label style={{ margin: "7px", display: "inline-block" }}>All publishers will be included in your fiscal report.</label>
                                        </label>
                                    </div>
                                </div>
                                <div id="half-left">
                                    <label>Start Date</label>
                                    <input
                                        name="fiscalstartDate"
                                        type="date"
                                        value={this.state.fiscalstartDate} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>End Date</label>
                                    <input
                                        name="fiscalendDate"
                                        type="date"
                                        value={this.state.fiscalendDate} onChange={this.handleChange}
                                    />
                                </div>
                                <div className="submit-section">
                                    <button onClick={this.getFiscalReport} type="button">Create Fiscal Report</button>
                                </div>

                            </div>
                            <div className="menu-choice" style={{ marginTop: '50px' }}>
                                {this.state.submitted &&
                                    <ReactTable
                                        data={this.state.publisherData}
                                        columns={this.state.columns}
                                        defaultPageSize={1}
                                        pageSizeOptions={[1]}
                                        showPagination={false}
                                    />
                                }
                            </div>
                            <div className="menu-choice" style={{ marginTop: '50px' }}>
                                {this.state.submitted &&
                                    <ReactTable
                                        data={this.state.Titles}
                                        pageSize={this.state.Titles.length}
                                        columns={titleColumns}
                                        pageSizeOptions={[1]}
                                        showPagination={false}
                                    />
                                }
                            </div>
                            <div className="menu-choice" style={{ marginTop: '50px' }}>
                                {this.state.fiscsubmitted &&
                                    <ReactTable
                                        data={this.state.titleData}
                                        columns={fiscColumns}
                                        defaultPageSize={1}
                                        pageSizeOptions={[1]}
                                        showPagination={false}
                                    />
                                }
                            </div>
                            <div className="menu-choice" style={{ marginTop: '50px' }}>
                                {this.state.fiscsubmitted &&
                                    <ReactTable
                                        data={this.state.Publishers}
                                        pageSize={this.state.Publishers.length}
                                        columns={fiscPublisherColumns}
                                        pageSizeOptions={[1]}
                                        showPagination={false}
                                        SubComponent={this.renderSubComponent}
                                    />
                                }
                            </div>
                        </div>
                    </div>


                </div>
            </React.Fragment>
        )
    }
}


function getUrlParameter(name, params) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(params);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default connect(
    (state) => {
        const { grid, login } = state;
        return {
            grid,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, gridActionCreators, authActionCreators), dispatch)
        }
    }
)(ReportContainer);
