import React from 'react';
import moment from "moment";
import ReactTable from 'react-table-6'
import "react-table-6/react-table.css";
class FormDetails extends React.Component {
    renderTableData() {
        return this.props.Titles.map((title, index) => {
            const { titleName, invoicebyTitlesNumber} = title //destructuring
            return (
                <tr key={titleName}>
                    <td>{titleName}</td>
                    <td>{invoicebyTitlesNumber}</td>
                </tr>
            )
        })
    }
    render() {
        console.log(this.props.publisherData)
        const IsData = (this.props.publisherData && this.props.publisherData.length > 0) ? true : false
        console.log(IsData)
        const headerStyle = {
            textAlign: 'center',
            fontWeight: 'bold'

        }
        const tdStyle = {
            width: '80%'

        }
        var now = moment().format("MM/DD/YYYY");

        return (

            <div className="hide" >
                <style scoped> {"@media all {table,th,td{border:1px solid black;padding:0.5em;} table{ width: 100%;border-collapse:collapse; margin-bottom:15px}}"} </style>
                {IsData &&
                    <table>
                        <thead>
                            <th>Number of acquisitions</th>
                            <th>Total spent</th>

                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.props.publisherData[0].numberofInvoices}</td>
                                <td>${this.props.publisherData[0].total}</td>
                            </tr>
                        </tbody>
                    </table>}
                {IsData &&
                    <table>
                    <thead>
                        <th>Title</th>
                        <th>Number of acquisitions per title</th>

                    </thead>
                    <tbody>
                        {this.renderTableData()}
                    </tbody>
                </table>}
                
            </div>
        );
    }
}


export default FormDetails;