import * as jwtDecode from 'jwt-decode';

const idToken = 'JCAR_ID_TOKEN';
const refreshToken = 'JCAR_REFRESH_TOKEN';

const loginRequest = 'LOGIN_REQUEST';
const loginSuccess = 'LOGIN_SUCCESS';
const loginFailure = 'LOGIN_FAILURE';

const logoutRequest = 'LOGOUT_REQUEST';
const logoutSuccess = 'LOGOUT_SUCCESS';

const validateError = 'VALIDATE_ERROR';
const validateSuccess = 'VALIDATE_SUCCESS';

const initialState = {
    loggedIn: false,
    validateMesage: '',
    userProfile: {},
    isAdministrator: false
};

const decodedToken = () => {
    const jwtToken = sessionStorage.getItem(idToken);

    const userData = {
        id: '',
        name: '',
        isAdmin: '',
    };

    if (jwtToken !== null && jwtToken !== 'undefined') {
        let decode = '';
        try {
            decode = jwtDecode(jwtToken);
        } catch(err) {
            sessionStorage.removeItem(idToken);
            sessionStorage.removeItem(refreshToken);
            return '';
        }
        const stringToken = JSON.stringify(decode);
        const parsedToken = JSON.parse(stringToken);
        userData.id = parsedToken.id;
        userData.hash = parsedToken.hash;
        userData.email = parsedToken.email;
        userData.exp = parsedToken.exp;
        return userData;
    }
}

const isTokenExpired = () => {
    const jwtToken = sessionStorage.getItem(idToken);

    var exp = '';

    if (jwtToken !== null && jwtToken !== 'undefined') {
        const decode = jwtDecode(jwtToken);
        const stringToken = JSON.stringify(decode);
        const parsedToken = JSON.parse(stringToken);

        exp = parsedToken.exp;
        return exp < new Date().getTime() / 1000;
    }
}

function request(url, method, headers, body, params) {
    let config = {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    }
    return fetch(url + params, config)
        .then(response => {
            if (response.status == 204) {
                throw [2, 'No Content'];
            } else if (response.status > 400) {
                throw [1, response.text()];
            } 
            return response
            
        })
        .then(response =>
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [0, data]
        })
        .catch(err => err);
    
} 

export const authActionCreators = {

    // LOGIN THE USER
    requestLogin: (creds) => ({
        type: loginRequest,
        isFetching: true,
        initiateRedirect: false,
        isAuthenticated: false,
        creds
    }),

    receiveLogin: (user) => ({
        type: loginSuccess,
        isFetching: false,
        initiateRedirect: true,
        isAuthenticated: true,
        isTokenExpired: isTokenExpired(user.AccessToken),
        userProfile: decodedToken(user.AccessToken)
    }),

    loginError: (message) => ({
        type: loginFailure,
        isFetching: false,
        isAuthenticated: false,
        message
    }),

    loginUser: (creds) => {
        const headers = { "Content-Type": "application/json; charset=utf-8" } 
        //const body = JSON.stringify({ username: creds.username, password: creds.password })
        const body = '';
        const params = `?username=${creds.username}&password=${creds.password}`
        return dispatch => {
            // dispatch requestLogin and kickoff the call to the API
            dispatch(authActionCreators.requestLogin(creds));

            return request('/api/Login/Authenticate', 'POST', headers, body, params)
                .then(result => { 
                    if (result[0] === 1) {
                        dispatch(authActionCreators.loginError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        sessionStorage.setItem(idToken, result[1].jwt);
                        // Dispatch the success action
                        dispatch(authActionCreators.receiveLogin(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(authActionCreators.loginError('Invalid Username or Password'));
                        return Promise.reject(result[1])
                    }
                })
                //.then( () => callback())
                .catch(err => console.log(err));
        }
    },

    // LOGOUT THE USER

    requestLogout: () => ({
        type: logoutRequest,
        isFetching: true,
        initiateRedirect: false,
        isAuthenticated: true
    }),

    receiveLogout: () => ({
        type: logoutSuccess,
        isFetching: false,
        initiateRedirect: false,
        isAuthenticated: false,
        userProfile: false
    }),

    logoutUser: () => {
        return dispatch => {
            dispatch(authActionCreators.requestLogout());
            sessionStorage.removeItem(idToken);
            sessionStorage.removeItem(refreshToken);
            dispatch(authActionCreators.receiveLogout())
        }
    },

    validateSuccess: (message) => ({
        type: validateSuccess,
        isAdministrator: message
    }), 

    validateError: (message) => ({
        type: validateError,
        validateMessage: message
    }), 

    validateUser: (email, hash) => {
        const headers = { "Content-Type": "application/json; charset=utf-8" }
        const body = hash;
        let params = '?username=' + email;
        return dispatch => {

            return request('/api/Login/Validate', 'POST', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(authActionCreators.validateError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(authActionCreators.validateSuccess(result[1].isAdministrator))
                    } else if (result[0] === 2) {
                        dispatch(authActionCreators.validateError('Invalid Username or Password'));
                        return Promise.reject(result[1])
                    }
                })
                //.then( () => callback())
                .catch(err => console.log(err));
        }
    },
};


export const reducer = (state, action) => {

    state = {
        ...state,
        isFetching: false,
        initiateRedirect: false,
        isAuthenticated: sessionStorage.getItem(idToken) !== null && sessionStorage.getItem(idToken) !== 'undefined' ? true : false,
        userProfile: decodedToken(),
        isTokenExpired: isTokenExpired()
    }

    switch (action.type) {
        case loginRequest:
            return Object.assign({}, state, {
                isFetching: true,
                initiateRedirect: false,
                isAuthenticated: false,
                user: action.creds
            })
        case loginSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                initiateRedirect: true,
                isAuthenticated: true,
                errorMessage: '',
                userProfile: action.userProfile,
                isTokenExpired: action.isTokenExpired
            })
        case loginFailure:
            return Object.assign({}, state, {
                isFetching: false,
                initiateRedirect: false,
                isAuthenticated: false,
                errorMessage: action.message
            })
        case logoutSuccess:
            return Object.assign({}, state, {
                isFetching: true,
                initiateRedirect: false,
                isAuthenticated: false
            })
        case validateSuccess:
            return Object.assign({}, state, {
                isAdministrator: action.isAdministrator
            })
        case validateError:
            return Object.assign({}, state, {
                validateMessage: action.validateMessage
            })
        default:
            return state
    }
};