const idToken = 'JCAR_ID_TOKEN';

const postFormFailure = 'POST_FORM_FAILURE';
const postFormSuccess = 'POST_FORM_SUCCESS';

const patchFormFailure = 'PATCH_FORM_FAILURE';
const patchFormSuccess = 'PATCH_FORM_SUCCESS';

const getFormFailure = 'GET_FORM_FAILURE';
const getFormSuccess = 'GET_FORM_SUCCESS';

const getFormsFailure = 'GET_FORMS_FAILURE';
const getFormsSuccess = 'GET_FORMS_SUCCESS';

const getContractTypesRequest = 'GET_contractTypes_REQUEST';
const getContractTypesFailure = 'GET_contractTypes_FAILURE';
const getContractTypesSuccess = 'GET_contractTypes_SUCCESS';

const getDlasContactsRequest = 'GET_dlasContacts_REQUEST';
const getDlasContactsFailure = 'GET_dlasContacts_FAILURE';
const getDlasContactsSuccess = 'GET_dlasContacts_SUCCESS';

const getCompaniesRequest = 'GET_Companies_REQUEST';
const getCompaniesFailure = 'GET_Companies_FAILURE';
const getCompaniesSuccess = 'GET_Companies_SUCCESS';



const headerLinkClickedSuccess = 'HEADER_LINK_CLICKED';

const initialState = {
    contractTypes: [],
    dlasContacts: [],
    companies: [],
    headerLinkClicked: false,
    formPostMessage: '',
    formPatchMessage: { "contractID": '' },
    formGetMessage: [],
    errorMessage: '',
    getContractTypesMessage: '',
    getDlasContactsMessage: '',
    getCompaniesMessage: '',
    formsMessage: '',
    formsList: []
}

function request(url, method, headers, body, params) {
    let config = { method: method }
    if (body) config.body = JSON.stringify(body);
    if (headers) config.headers = headers;
    return fetch(url + params, config)
        .then(response => {
            if (response.status == 204) {
                throw [2, 'No Content'];
            } else if (response.status > 400) {
                throw [1, response.text()];
            }
            console.log(response)
            return response

        })
        .then(response =>
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [0, data]
        })
        .catch(err => err);

}
function request1(url, method, headers, body, params) {
    let config = { method: method }
    if (body) config.body = body;
    if (headers) config.headers = headers;
    return fetch(url,
        {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json'
            },
            body: body
        }
    ).then(response => {
        if (response.status == 204) {
            throw [2, 'No Content'];
        } else if (response.status > 400) {
            throw [1, response.text()];
        }
        return response

    })
        .then(response =>
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [0, data]
        })
        .catch(err => err);

}

function requestFile(url, method, headers, body, params) {
    let config = { method: method }
    if (body) config.body = body;
    if (headers) config.headers = headers;
    return fetch(url + params,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json'
            },
            responseType: 'arraybuffer'
        }
    ).then(response => {
        if (response.status == 204) {
            throw [2, 'No Content'];
        } else if (response.status > 400) {
            throw [1, response.text()];
        }
        console.log(response)
        return response

    })
        .then(response =>
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [0, data]
        })
        .catch(err => err);

}
export const formActionCreators = {

    errorGetForm: (message) => ({
        type: getFormFailure,
        errorMessage: message,
        formGetMessage: []
    }),

    receiveGetForm: (message) => ({

        type: getFormSuccess,
        formGetMessage: message,
    }),

    getForm: (id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        return dispatch => {
            return request('/api/Contract/PublisherDetails/', 'GET', headers, '', id + '?jwt=' + jwt)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorGetForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action

                        dispatch(formActionCreators.receiveGetForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorGetForm('No Content'));
                        return Promise.reject(result[1])
                    } else {
                        dispatch(formActionCreators.errorGetForm('fail'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getTitle: (id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        return dispatch => {
            return request('/api/Company/Title/', 'GET', headers, '', id + '?jwt=' + jwt)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorGetForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action

                        dispatch(formActionCreators.receiveGetForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorGetForm('No Content'));
                        return Promise.reject(result[1])
                    } else {
                        dispatch(formActionCreators.errorGetForm('fail'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getPublicTitle: (id) => {
        const headers = { "Content-Type": "application/json" };
        return dispatch => {
            return request('/api/TitlePublic/Title/', 'GET', headers, '', id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorGetForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action

                        dispatch(formActionCreators.receiveGetForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorGetForm('No Content'));
                        return Promise.reject(result[1])
                    } else {
                        dispatch(formActionCreators.errorGetForm('fail'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getInvoice: (id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        return dispatch => {
            return request('/api/Invoice/Invoice/', 'GET', headers, '', id + '?jwt=' + jwt)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorGetForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action

                        dispatch(formActionCreators.receiveGetForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorGetForm('No Content'));
                        return Promise.reject(result[1])
                    } else {
                        dispatch(formActionCreators.errorGetForm('fail'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getFile: (id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        return dispatch => {
            return requestFile('/api/Invoice/ByteArray/', 'GET', headers, '', id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorGetForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action

                        dispatch(formActionCreators.receiveGetForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorGetForm('No Content'));
                        return Promise.reject(result[1])
                    } else {
                        dispatch(formActionCreators.errorGetForm('fail'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    errorPatchForm: (message) => ({
        type: patchFormFailure,
        errorMessage: message,
    }),

    receivePatchForm: (message) => ({
        type: patchFormSuccess,
        formPatchMessage: message,
    }),

    patchForms: (form, id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        form.jwt = jwt
        const body = form
        return dispatch => {
            return request('/api/reports/reports/', 'PATCH', headers, body, id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorPatchForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(formActionCreators.receivePatchForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorPatchForm('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    saveContracts: (form, id) => {
        console.log(id)
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        form.jwt = jwt
        const body = form
        return dispatch => {
            return request('/api/Contract/Publisher/', 'PUT', headers, body, id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorPatchForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(formActionCreators.receivePatchForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorPatchForm('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    saveTitle: (form, id) => {
        console.log(id)
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        form.jwt = jwt
        const body = form
        return dispatch => {
            return request('/api/Company/Title/', 'POST', headers, body, id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorPatchForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(formActionCreators.receivePatchForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorPatchForm('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    updateTitle: (form, id) => {
        console.log(id)
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        form.jwt = jwt
        const body = form
        return dispatch => {
            return request('/api/Company/UpdateTitle/', 'POST', headers, body, id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorPatchForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(formActionCreators.receivePatchForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorPatchForm('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    saveInvoice: (id, file) => {
        const headers = { "Content-Type": "application/json" };
        const jwt = sessionStorage.getItem(idToken)
        file.append("jwt", jwt)
        file.append("titleID", id)

        const body = file
        console.log(body);

        return dispatch => {
            return request1('/api/Invoice/Invoice/', 'POST', headers, body, id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorPatchForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(formActionCreators.receivePatchForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorPatchForm('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    updateInvoice: (id, file) => {
        console.log(id)
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        file.append("jwt", jwt)
        file.append("invoiceID", id)

        const body = file
        return dispatch => {
            return request1('/api/Invoice/UpdateInvoice/', 'POST', headers, body, id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorPatchForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(formActionCreators.receivePatchForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorPatchForm('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    errorPostForm: (message) => ({
        type: postFormFailure,
        errorMessage: message,
    }),

    receivePostForm: (message) => ({
        type: postFormSuccess,
        formPostMessage: message,

    }),

    postForms: (form) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json" };
        form.jwt = jwt
        const body = form
        console.log(body);
        return dispatch => {
            return request('/api/Contract/Publisher/', 'POST', headers, body, '')
                .then(result => {
                    console.log(result);
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorPostForm(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action

                        dispatch(formActionCreators.receivePostForm(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorPostForm('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },

    requestcontractTypes: () => ({
        type: getContractTypesRequest,
        contractTypes: [],
        isFetching: true
    }),

    errorcontractTypes: (message) => ({
        type: getContractTypesFailure,
        contractTypes: [],
        isFetching: false,
        getContractTypesMessage: message
    }),

    receivecontractTypes: (responseObj) => ({
        type: getContractTypesSuccess,
        contractTypes: responseObj,
        isFetching: false
    }),

    getContractTypes: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        //const body = JSON.stringify({ jwt: jwt });
        const body = '';
        const params = '?jwt=' + jwt;
        return dispatch => {
            dispatch(formActionCreators.requestcontractTypes());

            return request('/api/ReferenceData/ContractTypes/', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorcontractTypes(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        console.log(result)
                        // Dispatch the success action
                        dispatch(formActionCreators.receivecontractTypes(result[1]))
                        console.log(formActionCreators.contractTypes)
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorcontractTypes('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },

    requestdlasContacts: () => ({
        type: getDlasContactsRequest,
        dlasContacts: [],
        isFetching: true
    }),

    errordlasContacts: (message) => ({
        type: getDlasContactsFailure,
        dlasContacts: [],
        isFetching: false,
        getDlasContactsMessage: message
    }),

    receivedlasContacts: (responseObj) => ({
        type: getDlasContactsSuccess,
        dlasContacts: responseObj,
        isFetching: false
    }),
    getDlasContacts: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        //const body = JSON.stringify({ jwt: jwt });
        const body = '';
        const params = '?jwt=' + jwt;
        return dispatch => {
            dispatch(formActionCreators.requestdlasContacts());

            return request('/api/ReferenceData/DlasContacts/', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errordlasContacts(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        console.log(result)
                        // Dispatch the success action
                        dispatch(formActionCreators.receivedlasContacts(result[1]))
                        console.log(formActionCreators.dlasContacts)
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errordlasContacts('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },

    requestCompanies: () => ({
        type: getCompaniesRequest,
        companies: [],
        isFetching: true
    }),

    errorCompanies: (message) => ({
        type: getCompaniesFailure,
        companies: [],
        isFetching: false,
        getCompaniesMessage: message
    }),

    receiveCompanies: (responseObj) => ({
        type: getCompaniesSuccess,
        companies: responseObj,
        isFetching: false
    }),
    getCompanies: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        //const body = JSON.stringify({ jwt: jwt });
        const body = '';
        const params = '?jwt=' + jwt;
        return dispatch => {
            dispatch(formActionCreators.requestCompanies());
            return request('/api/ReferenceData/Companies/', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorCompanies(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        console.log(result)
                        // Dispatch the success action
                        dispatch(formActionCreators.receiveCompanies(result[1]))

                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorCompanies('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    errorForms: (message) => ({
        type: getFormsFailure,
        formList: [],
        formsMessage: message
    }),

    receiveForms: (responseObj) => ({
        type: getFormsSuccess,
        formsList: responseObj,
    }),

    getForms: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        //const body = JSON.stringify({ jwt: jwt });
        const body = '';
        const params = '?jwt=' + jwt;
        return dispatch => {
            return request('/api/reports/reports', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(formActionCreators.errorForms(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(formActionCreators.receiveForms(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(formActionCreators.errorForms('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },

    clickHeaderLink: (bool) => ({
        type: headerLinkClickedSuccess,
        headerLinkClicked: bool
    }),


};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case getFormFailure:
            return Object.assign({}, state, {
                errorMessage: action.errorGetForm
            })
        case getFormSuccess:
            return Object.assign({}, state, {
                formGetMessage: action.formGetMessage
            })
        case getFormsFailure:
            return Object.assign({}, state, {
                formsMessage: action.formsMessage
            })
        case getFormsSuccess:
            return Object.assign({}, state, {
                formsList: action.formsList
            })
        case patchFormFailure:
            return Object.assign({}, state, {
                errorMessage: action.errorPatchForm
            })
        case patchFormSuccess:
            return Object.assign({}, state, {
                formPatchMessage: action.formPatchMessage,
            })
        case postFormFailure:
            return Object.assign({}, state, {
                errorMessage: action.errorPostForm
            })
        case postFormSuccess:
            return Object.assign({}, state, {
                formPostMessage: action.formPostMessage,
            })
        case getContractTypesRequest:
            return Object.assign({}, state, {
                contractTypes: action.contractTypes
            })
        case getContractTypesFailure:
            return Object.assign({}, state, {
                isFetching: false,
                getContractTypesMessage: action.getContractTypesMessage
            })
        case getContractTypesSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                contractTypes: action.contractTypes,
                getContractTypesMessage: ''
            })
        case getDlasContactsRequest:
            return Object.assign({}, state, {
                dlasContacts: action.dlasContacts
            })
        case getDlasContactsFailure:
            return Object.assign({}, state, {
                isFetching: false,
                getDlasContactsMessage: action.getDlasContactsMessage
            })
        case getDlasContactsSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                dlasContacts: action.dlasContacts,
                getDlasContactsMessage: ''
            })
        case getCompaniesRequest:
            return Object.assign({}, state, {
                companies: action.companies
            })
        case getCompaniesFailure:
            return Object.assign({}, state, {
                isFetching: false,
                getCompaniesMessage: action.getCompaniesMessage
            })
        case getCompaniesSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                companies: action.companies,
                getCompaniesMessage: ''
            })
        case headerLinkClickedSuccess:
            return Object.assign({}, state, {
                headerLinkClicked: action.headerLinkClicked
            })
        default:
            return state;
    }
};