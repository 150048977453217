import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../scripts/store/Login';
import { formActionCreators } from '../scripts/store/Form';



const Footer = (props) => {


    return (
        <footer id="footer" role="contentinfo">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="footer editable" id="untitled-region-1"><p class="footer-links"> <a href="http://dls.virginia.gov/">DLS Home</a> | <a href="privacy.html">Privacy Statement</a> | <a href="http://lis.virginia.gov/">Legislative
                            Information System</a> | <a href="http://virginiageneralassembly.gov/">Virginia General Assembly</a>| <a href="/Contact">Contact US</a></p>
                            <p class="copy">© Copyright Commonwealth of Virginia, 2022. All rights reserved.</p>

                            <p class="copy">Site designed and developed by the <a href="http://dlas.virginia.gov">Division of Legislative Automated Systems (DLAS)</a>.</p></div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default withRouter(connect(
    (state) => {
        const { login } = state;
        return {
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, formActionCreators), dispatch)
        }
    }
)(Footer))