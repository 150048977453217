import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { formActionCreators } from '../scripts/store/Form';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactToPrint from 'react-to-print';
import ContractDetails from './ContractDetails';
import "react-datepicker/dist/react-datepicker.css";
import '../stylesheets/form.css';
import FileBase64 from 'react-file-base64';
import { forEach } from 'lodash';

const FORM_ERRORS = 1;



class TitleFormContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            dlasContactName: '',
            dlasContactID: null,
            companyID: null,
            contractType: '',
            contractTypeID: null,
            year: null,
            dataChanged: false,
            id: this.props.match.params.id,
            submitted: false,
            showPopup: false,
            showSubmitPopup: false,
            period: '',
            agencyID: '',
            contractTypes: [],
            dlasContacts: [],
            companies: [],
            existingExemptions: [],
            exemption: '',
            citation: '',
            isRequisition: false,
            combineReq1: false,
            combineReq2: false,
            Type: '',
            contractItem: '',
            contractNumber: '',
            requisitionNumber: '',
            startDate: '',
            endDate: '',
            remainingRenewals: '',
            Price: '',
            companyName: '',
            companyAddress: '',
            companyCity: '',
            companyState: '',
            companyZipCode: '',
            companyFax: '',
            companyEmail: '',
            companyFin: '',

            contactName: '',
            contactPhone: '',
            contactFax: '',
            contactEmail: '',
            noteText: '',
            v: [],
            noteDetails: [
                {
                    textID: '',
                    noteText: '',
                    isDeleted: false,
                    files: [
                        {
                            fileName: '',
                            fileUrl: '',
                            mimeType: '',
                            fileID: 0,
                            byteArray: ''
                        }
                    ]
                }
            ],
            paperWeight: '',
            quantity: '',
            Pages: null,
            Volumes: null,
            availabilityList: [
                {
                    availabilityID: 0,
                    text: "Available",
                },
                {
                    availabilityID: 1,
                    text: "Internally Only",
                },
                {
                    availabilityID: 2,
                    text: "Cancelled",
                },
            ],
            combineOrderID: null,
            newCompanyName: '',
            hideText: "hidden",
            hideButton: true,
            headerStyle: {
                float: 'left',
                width: '48%',
                marginTop: '5px',
                padding: '10px'
            },
            
            publisher: '',
            addr1: '',
            addr2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            fax: '',
            notes: '',
            titlesbyPublisherNumber: '',
            accountNum: '',
            frequency: '',
            pubtype: '',
            shelfNumber: '',
            author: '',
            callNumber: '',
            availabilityID: null,
            titleName: ''
            
        };

        this.props.actions.clickHeaderLink(false)
        this.handleChange = this.handleChange.bind(this);
        this.getTitleData = this.getTitleData.bind(this);
        this.saveTitle = this.saveTitle.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.toggleSubmitPopup = this.toggleSubmitPopup.bind(this);
        this.delete = this.delete.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
    }

  
    handleChange(e) {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({
            [key]: value
        });
    }

    blurHandler() {
        this.setState({
            showPopup: false
        })
    }

    delete(id) {
        this.setState(prevState => ({
            v: prevState.noteDetails.forEach(function (o) {
                let item = o.files.filter(f => f.fileID == id)
                let index = o.files.findIndex(f => f.fileID == id)
                if (index > -1) {
                    item[0].isDeleted = true;
                    o.files[index] = item[0];
                }
            })
        }));
    }


    getTitleData(isSubmitting) {
        let formData = {};

        let hasErrors = 0;
        let nonEmptyNotes = [];


        formData = {
            ...formData,
            titleName: this.state.titleName,
            accountNum: this.state.accountNum,
            frequency: this.state.frequency,
            pubtype: this.state.pubtype,
            shelfNumber: this.state.shelfNumber,
            author: this.state.author,
            callNumber: this.state.callNumber,
            availabilityID: parseInt(this.state.availabilityID),
            notes: this.state.notes
        }

        //If it has errors then don't send any data
        if (hasErrors) {
            this.setState({
                hasErrors: true
            })
            return FORM_ERRORS;
        } else {
            this.setState({
                hasErrors: false
            })
            return formData;
        }
    }



    saveTitle() {
        let formData = this.getTitleData(this.state.submitted);
        console.log(formData);
        if (formData !== FORM_ERRORS) {
            //Show the popup once the save is donew
            if (this.props.match.params.id) {
                //this is where th save happens
                this.props.actions.saveTitle(formData, this.props.match.params.id)
                    .then(() => {
                        let newNoteDetails = this.state.noteDetails;
                        this.setState({
                            noteDetails: newNoteDetails,
                            dataChanged: false
                        });
                    })
                    .then(() => this.togglePopup());
            } 
        }
    }

    unsavedDataPrompt(link) {
        if (this.state.dataChanged) {
            this.setState({
                showPrompt: true,
                nextLink: link
            })
        } else {
            this.props.history.push(link);
        }
        this.props.actions.clickHeaderLink(false);
    }

    toggleSubmitPopup() {
        this.setState(state => ({
            showSubmitPopup: !state.showSubmitPopup,
            showPopup: !state.showPopup
        }));
    }

    togglePopup() {
        this.setState(state => ({
            showPopup: !state.showPopup
        }))
        setTimeout(() => { this.props.history.push("/Publisher/" + this.state.id); this.setState({ showPopup: false }) }, 2000);

    }

    toggleCheckboxReq() {
        this.setState(state => ({
            isRequisition: !state.isRequisition
        }));
    }

    handleCompanyChange(e) {
        const selectedCompany = this.state.companies.find(company => company.companyID == e.target.value);
        const key = e.target.name;
        this.setState({
            [key]: e.target.value,

            companyAddress: selectedCompany.companyAddress,
            companyCity: selectedCompany.companyCity,
            companyState: selectedCompany.companyState,
            companyZipCode: selectedCompany.companyZipCode,
            companyFax: selectedCompany.companyFax,
            companyEmail: selectedCompany.companyEmail,
            companyFin: selectedCompany.companyFin,

            contactName: selectedCompany.contactName,
            contactPhone: selectedCompany.contactPhone,
            contactFax: selectedCompany.contactFax,
            contactEmail: selectedCompany.contactEmail
        });
    }
    componentDidMount() {
        //Scroll to top of page since clicking a link far down on the grid will keep the same scroll position
        window.scrollTo(0, 0);
        if (this.props.match.params.id) {
            //Form has an ID so this is an update, set the data for that form
            this.props.actions.getForm(this.props.match.params.id)
                .then(() => {
                    if (this.props.form.formGetMessage) {
                        const formData = this.props.form.formGetMessage;

                        this.setState({
                            publisherID: formData.publisherID,
                            publisher: formData.publisher,

                        }, function () {
                            
                        });
                    }
                })

            //this.props.actions.getContractTypes().then(() => {
            //    const companyData = this.props.form.getCompaniesMessage;           
            //    this.setState({
            //        contractTypes: this.props.form.contractTypes
            //    })
            //});
            //this.props.actions.getDlasContacts().then(() => {
            //    this.setState({
            //        dlasContacts: this.props.form.dlasContacts
            //    })
            //});



        }
        else {
            //this.props.actions.getContractTypes().then(() => {
            //    this.setState({
            //        contractTypes: this.props.form.contractTypes
            //    })
            //});
            //this.props.actions.getDlasContacts().then(() => {
            //    this.setState({
            //        dlasContacts: this.props.form.dlasContacts
            //    })
            //});
            //this.props.actions.getCompanies().then(() => {
            //    this.setState({
            //        companies: this.props.form.companies
            //    })
            //});
        }

    }

    componentDidUpdate(prevProps) {

        if (prevProps.form.headerLinkClicked !== this.props.form.headerLinkClicked) {
            if (this.props.form.headerLinkClicked) {
                this.unsavedDataPrompt('/Publishers')
            }
        }
    }

    render() {
       
        return (
            <React.Fragment>
                <div id="form-container">
                    {this.state.showPopup &&
                        <div tabindex="0" className="popup-container" onBlur={this.blurHandler}>
                            <div tabindex="0" className="popup">
                                <div tabindex="0" className="popup-head">
                                    {this.state.showSubmitPopup ? <span>Form has been submitted</span> : <span>Saved successfully</span>}
                                </div>
                                <div className="popup-body" >
                                    <span>Your Title has been saved</span>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="table-header">
                     
                        <h4><Link className="headerlink-Button" type="button" to={'/Publishers'}>Publishers</Link>›
                            <Link className="headerlink-Button" type="button" to={'/Publisher/' + this.props.match.params.id}>{this.state.publisher}</Link>›
                            Add Title
                        </h4>
                        <h3>{this.state.period}</h3>
                        <div>

                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>&lsaquo; Back</button>
                        </div>
                    </div>


                    <div id="agency-info" className="form-section" style={{ padding: '30px', paddingBottom: '250px' }}>
                            <h4>Title Information</h4>
                                <div>
                                    <label>Title Name</label>
                                    <input
                                        name="titleName"
                                        type="text"
                                        value={this.state.titleName} onChange={this.handleChange}
                                    />
                                </div>
                                <div>
                                    <label>Publisher</label>
                                    <input
                                        name="publisher"
                                        type="text"
                                        value={this.state.publisher} onChange={this.handleChange}
                                        disabled
                                    />
                                </div>
                                <div id="half-left">
                                    <label>Frequency</label>
                                    <input
                                        name="frequency"
                                        type="text"
                                        value={this.state.frequency} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Publication Type</label>
                                    <input
                                        name="pubtype"
                                        type="text"
                                        value={this.state.pubtype} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-left">
                                    <label>Shelf Number</label>
                                    <input
                                        name="shelfNumber"
                                        type="text"
                                        value={this.state.shelfNumber} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Author</label>
                                    <input
                                        name="author"
                                        type="text"
                                        value={this.state.author} onChange={this.handleChange}
                                    />
                                </div>                                                             
                                <div id="half-left">
                                    <label>Call Number</label>
                                    <input
                                        name="callNumber"
                                        type="text"
                                        value={this.state.callNumber} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Availability</label>
                                    <select name="availabilityID" value={this.state.availabilityID} onChange={this.handleChange}>
                                        <option value="" selected disabled hidden>Select...</option>
                                        {this.state.availabilityList.map((availability) => <option value={availability.availabilityID}>{availability.text}</option>)}
                                    </select>
                                </div>                                                
                    </div>

                    <div className="submit-section">
                        <button onClick={this.saveTitle} type="button">Save</button>
                    </div>

                    <div className="button-menu">
                        <div style={{ float: 'right' }}>
                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>&lsaquo; Back</button>
                        </div>
                        {this.props.match.params.id &&
                            <div style={{ float: 'left' }}>
                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>Delete Publisher <span className="icon destroy"></span></button>
                            </div>}
                        {this.state.hasErrors && <span className="error-text">Required fields are missing</span>}
                        <ReactToPrint
                            copyStyles={false}
                            trigger={() => <button type="button" id={!this.state.isRequisition ? 'hidden' : undefined}>Print</button>}
                            content={() => this.componentRef}
                            pageStyle={{ size: 'auto', margin: '0px' }}
                        />
                    </div>
                    <ContractDetails
                        {...this.state}
                        ref={el => (this.componentRef = el)}
                        agencies={this.props.form.dlasContacts}
                        period={this.state.companyCity} />
                </div>
            </React.Fragment>
        )
    }
}

class NoteDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            noteFiles: []

        }
        this.getFiles = this.getFiles.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.onChange(e.target.name, e.target.value, this.props.index);
    }

    removeNote() {
        this.props.onChange('isDeleted', !this.props.noteDetail.isDeleted, this.props.index)
    }
    delete(id) {
        this.props.delete(id);
    }

    getFiles(files) {
        this.setState({ files: files })
        files.forEach(f =>
            this.props.noteDetail.files.push({ "fileID": 0, "fileName": f.name, "mimeType": f.type, "byteArray": f.base64.split(',')[1], "noteID": this.props.noteDetail.noteID, "isDeleted": this.props.noteDetail.isDeleted })
        )
    }

    render() {

        const isDisabled = this.props.isDisabled || this.props.noteDetail.isDeleted;
        return (
            <div>
                <div className="section-head flex-row">
                    <div className="remove-note-container">
                        {isDisabled ?
                            <span title="Add Note back" onClick={this.removeNote.bind(this)} className="icon addback"></span>
                            :
                            <span title="Remove Note" onClick={this.removeNote.bind(this)} className="icon destroy"></span>
                        }
                    </div>
                </div>

                <div className="section-body">
                    <div className="input-container no-padding-right">
                        <textarea disabled={isDisabled} name="noteText" onChange={this.handleChange} value={this.props.noteDetail.noteText} />
                        <div className="upload-container" disabled={isDisabled}>

                            <fieldset disabled={isDisabled} className="fieldset-upload">
                                <button disabled={isDisabled} className="button label-upload" htmlFor="uploadForm">Attach file</button>
                                <FileBase64 className="upload-input"
                                    multiple={true}
                                    onDone={this.getFiles.bind(this)} />
                            </fieldset>

                        </div>

                        <div class="files-uploaded">
                            <div className="file-upload-desc">
                                {this.state.files.map((file, index) =>
                                    <div className='new-line' disabled={isDisabled}>{file.name}</div>)}
                            </div>
                            {this.props.noteDetail.files.filter(s => s.isDeleted == false).map((noteFile, index) =>

                                <div className='new-line'><a disabled={isDisabled} href={noteFile.fileUrl} download>{noteFile.fileName}</a>  <span title="Delete file" onClick={this.delete.bind(this, noteFile.fileID)} className="icon destroy"></span></div>)}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


export default connect(
    (state) => {
        const { form } = state;
        return {
            form
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, formActionCreators), dispatch)
        }
    }
)(TitleFormContainer);
