import './stylesheets/global.css';

import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router';
import Layout from './components/Layout';
import Login from './components/Login';
import Publishers from './components/Publishers'
import Titles from './components/Titles'
import Invoices from './components/Invoices'
import FormContainer from './components/ContractForm'
import PubTitlesContainer from './components/PublisherTitles'
import TitleInvoicesContainer from './components/TitleInvoices'
import TitleFormContainer from './components/AddNewTitle'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from './scripts/store/Login';
import InvoiceFormContainer from './components/AddNewInvoice'
import EditTitleContainer from './components/EditTitle'
import EditInvoiceContainer from './components/EditInvoice'
import ReportContainer from './components/Reports'
import Home from './components/Home'
import History from './components/History'
import Contact from './components/Contact'
import Title from './components/Title'
import Collections from './components/Collections'
import Resources from './components/Resources'
import About from './components/About'
// Private route component for auth-only paths. If authstatus == true, show the component.
// Will eventually need to add on to it for Admin-only paths
const PrivateRoute = ({ authstatus, component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        authstatus
            ? <Component {...props} />
            : <Redirect to='/Home' />
    )} />
)

const LoginPath = (({ authstatus, component: Component, ...rest }) =>
    <Route {...rest} render={(props) => (
        authstatus
            ? < Redirect to='/Publishers' />
            : <Component {...props} />
    )} />
)

class App extends Component {

    render() {
        const { isAuthenticated } = this.props.login;
        return (
            <Layout>
                <LoginPath exact path='/' authstatus={isAuthenticated} component={Home} />
                <LoginPath exact path='/Home' authstatus={isAuthenticated} component={Home} />
                <LoginPath exact path='/History' authstatus={isAuthenticated} component={History} />
                <LoginPath exact path='/Contact' authstatus={isAuthenticated} component={Contact} />
                <LoginPath exact path='/Collections' authstatus={isAuthenticated} component={Collections} />
                <LoginPath exact path='/About' authstatus={isAuthenticated} component={About} />
                <LoginPath exact path='/Resources' authstatus={isAuthenticated} component={Resources} />
                <LoginPath exact path='/login' authstatus={isAuthenticated} component={Login} />
                <LoginPath exact path='/Title/:id' authstatus={isAuthenticated} component={Title} />
                <PrivateRoute path='/Publishers' authstatus={isAuthenticated} component={Publishers} />
                <PrivateRoute path='/Titles' authstatus={isAuthenticated} component={Titles} />
                <PrivateRoute path='/Invoices' authstatus={isAuthenticated} component={Invoices} />
                <PrivateRoute exact path='/Publisher' authstatus={isAuthenticated} component={FormContainer} />
                <PrivateRoute exact path='/Publisher/:id' authstatus={isAuthenticated} component={FormContainer} />
                <PrivateRoute exact path='/PublisherTitles/:id' authstatus={isAuthenticated} component={PubTitlesContainer} />
                <PrivateRoute exact path='/TitleInvoices/:id' authstatus={isAuthenticated} component={TitleInvoicesContainer} />
                <PrivateRoute exact path='/AddNewTitle/:id' authstatus={isAuthenticated} component={TitleFormContainer} />
                <PrivateRoute exact path='/AddNewInvoice/:id' authstatus={isAuthenticated} component={InvoiceFormContainer} />
                <PrivateRoute exact path='/EditTitle/:id' authstatus={isAuthenticated} component={EditTitleContainer} />
                <PrivateRoute exact path='/EditInvoice/:id' authstatus={isAuthenticated} component={EditInvoiceContainer} />
                <PrivateRoute exact path='/Reports' authstatus={isAuthenticated} component={ReportContainer} />
            </Layout>
        );
    }
}

export default withRouter(connect(
    (state) => {
        const { login } = state;
        return {
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators), dispatch)
        }
    }
)(App))