import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { gridActionCreators } from '../scripts/store/Grid';
import { authActionCreators } from '../scripts/store/Login';
import '../stylesheets/grid.css';
import Select from 'react-select';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"
//import 'bootstrap/dist/css/bootstrap.min.css';
import firstPicture from '../images/1resize.jpg'
import libraryPicture from '../images/library.jpg'
import servicesPicture from '../images/meta-chart.jpg'
import ourmissionpicture from '../images/ourmission.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faCalendarDays, faRectangleList, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            selectedRow: '',
            rows: [],
            filteredRows: [],
            selectedRows: [],
            selectAll: false,
            filterOptions: [],
            publisherFilter: 'all',
            contractTypeFilter: 'all',
            prefix: '',
            suggestion: '',
            items: [
                {
                    value: 'Cobol',
                    label: 'Cobol'
                },
                {
                    value: 'JavaScript',
                    label: 'JavaScript'
                },
                {
                    value: 'Basic',
                    label: 'Basic'
                },
                {
                    value: 'PHP',
                    label: 'PHP'
                },
                {
                    value: 'Java',
                    label: 'Java'
                }
            ]
        };

        this.handleCommitteeChange = this.handleCommitteeChange.bind(this);
    }

    componentDidMount() {
        this.props.actions.getPublicTitles()
            .then(() => {
                let rows = this.props.grid.rowList;
                //Add the index (for finding the row) and the checked property (for toggling the checkbox)
                rows.forEach(title => {
                    title.label = title.titleName;
                    title.value = title.titleID;
                });
                console.log(rows);
                this.setState(state => (
                    {
                        rows: rows,
                    }
                ));
            })
    }
    handleCommitteeChange(val) {
        this.props.history.push("/Title/" + val.titleID);
        console.log(val)
    }


    render() {
        return (
            <div>
                <div className="table-header">
                </div>
                <div className="top-cover" style={{
                  backgroundImage: "url(" + libraryPicture + ")"  
                }}>

                    <Select
                        id="sr-committee"
                        options={this.state.rows}
                        placeholder="Search Collections"
                        value={this.state.selectedTitle}
                        onChange={this.handleCommitteeChange}
                        className="grid-selector-dropdown"
                        classNamePrefix="grid-selector-dropdown"
                        style={{
                            width: '100%', // Ensure the Select component spans the full width
                        }}
                    />

                </div>
                <div className="menu-choice">
                    <div class="col-lg-4" >
                        <div class="card cardNoBorder">
                            <div class="card-img-top">&nbsp;</div>
                            <div class="card-body">
                                <a href='/Collections'>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} className="fontIcon" ></FontAwesomeIcon>
                                </a>
                                <h5 class="card-title" style={{ 'textAlign': "center" }}>Online Database</h5>
                                <p class="card-text" style={{ 'textAlign': "center", 'margin': "15px 25px 21px" }}>Search the Legislative Reference Center online database for documents availability and location in the library</p>
                                <p style={{ 'textAlign': "center" }}><a class="btn btn-default" href="/Collections">Search Collections</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card cardNoBorder">
                            <div class="card-img-top">&nbsp;</div>
                            <div class="card-body">
                                <a href='http://dls.virginia.gov/pubs/LegislativeGuidelines.pdf' target='_blank'>
                                    <FontAwesomeIcon icon={faRectangleList} className="fontIcon" ></FontAwesomeIcon>
                                </a>
                                <h5 class="card-title" style={{ 'textAlign': "center" }}>Legislative Guidelines</h5>
                                <p class="card-text" style={{ 'textAlign': "center", 'margin': "43px 13px 17px"  }}>Legislative Guidelines Applicable to Studies, Legislative Commissions, Nonlegislative Collegial Bodies, and Resolutions (from the Joint Rules Committee).</p>
                                <p class="card-text" style={{ 'textAlign': "center" }}><a class="btn btn-default" href="http://dls.virginia.gov/pubs/LegislativeGuidelines.pdf" target="_blank">View Document</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card cardNoBorder">
                            <div class="card-img-top">&nbsp;</div>
                            <div class="card-body">
                            <a href='http://dls.virginia.gov/pubs_calendar.html' target='_blank'><FontAwesomeIcon icon={faCalendarDays} className="fontIcon" ></FontAwesomeIcon></a>
                                <h5 class="card-title" style={{ 'textAlign': "center" }}>Session Calendars</h5>
                                <p class="card-text" style={{ 'textAlign': "center", 'margin': "43px 13px 17px" }}>Session calendars and procedural resolutions from 2002 to the present governing the conduct of business for the Regular Sessions of the Virginia General Assembly.</p>
                                <p class="card-text" style={{ 'textAlign': "center" }}><a class="btn btn-default" href="http://dls.virginia.gov/pubs_calendar.html" target="_blank">View Session Calendars</a></p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4" style={{ paddingRight: '0px' }}>
                        <div class="card cardNoBorder">
                        <div class="card-img-top">&nbsp;</div>
                            <div class="card-body">
                            <a href='http://dls.virginia.gov/publications.html' target='_blank'><FontAwesomeIcon icon={faFileContract} className="fontIcon" ></FontAwesomeIcon></a>
                                <h5 class="card-title" style={{ 'textAlign': "center" }}>Publications</h5>
                                <p class="card-text" style={{ 'textAlign': "center", 'margin': "1px" }}>Session Publications</p>
                                <p class="card-text" style={{ 'textAlign': "center", 'margin': "1px" }}>Legislator Guides</p>
                                <p class="card-text" style={{ 'textAlign': "center", 'margin': "1px" }}>Redistricting Publications</p>
                                <p class="card-text" style={{ 'textAlign': "center", 'margin': "1px" }}>Historical Reports</p>
                                <p class="card-text" style={{ 'textAlign': "center", 'margin': "1px" }}>Other Publications</p>
                                <p class="card-text" style={{ 'textAlign': "center" }}><a class="btn btn-default" href="http://dls.virginia.gov/publications.html" target="_blank">View Publications</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="carousel-container">
                        <Carousel>
                            <div>
                                <img src={firstPicture} />
                                <p className="legend1">E-RESOURCES - Free Access</p>
                            </div>
                            <div>
                                <img src={servicesPicture} />
                                <p className="legend1">What We Do</p>
                            </div>
                            <div>
                                <img src={ourmissionpicture} />
                                <p className="legend1">Legal and Historical Research Services</p>
                            </div>
                        </Carousel>
                    </div>

                    <div className="info">
                        <h2>Location/Hours</h2>
                        <p>The Legislative Reference Center (LRC) is located in the Pocahontas Building at 900 E. Main Street on the 11th floor.</p>
                        <p>The LRC is currently open by appointment between 8:30 a.m. and 5 p.m. Monday through Friday. Please contact us for more information.</p>
                    </div>
                </div>
                <div class="push"></div>
            </div>
        )
    }
}








function getUrlParameter(name, params) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(params);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default connect(
    (state) => {
        const { grid, login } = state;
        return {
            grid,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, gridActionCreators, authActionCreators), dispatch)
        }
    }
)(Home);