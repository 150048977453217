import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { formActionCreators } from '../scripts/store/Form';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactToPrint from 'react-to-print';
import ContractDetails from './ContractDetails';
import "react-datepicker/dist/react-datepicker.css";
import '../stylesheets/form.css';
import FileBase64 from 'react-file-base64';
import { forEach } from 'lodash';

const FORM_ERRORS = 1;



class TitleContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hideText: "hidden",
            hideButton: true,
            headerStyle: {
                float: 'left',
                width: '48%',
                marginTop: '5px',
                padding: '10px'
            },

            publisher: '',
            publisherID: '',
            addr1: '',
            addr2: '',
            city: '',
            state: '',
            zip: '',
            pubtype: '',
            fax: '',
            titlesbyPublisherNumber: '',
            accountNum: '',
            frequency: '',
            phone: '',
            shelfNumber: '',
            author: '',
            callNumber: '',
            availability: 'Available',
            titleName: ''

        };

        this.props.actions.clickHeaderLink(false)
    }











    componentDidMount() {
        //Scroll to top of page since clicking a link far down on the grid will keep the same scroll position
        window.scrollTo(0, 0);
        if (this.props.match.params.id) {
            //Form has an ID so this is an update, set the data for that form
            this.props.actions.getPublicTitle(this.props.match.params.id)
                .then(() => {
                    if (this.props.form.formGetMessage) {
                        const formData = this.props.form.formGetMessage;

                        this.setState({
                            titleID: formData.titleID,
                            publisher: formData.publisher,
                            publisherID: formData.publisherID,
                            frequency: formData.frequency,
                            pubtype: formData.pubtype,
                            shelfNumber: formData.shelfNumber,
                            author: formData.author,
                            callNumber: formData.callNumber,
                          /*  availability: formData.availability,*/
                            titleName: formData.titleName

                        }, function () {

                        });
                    }
                })




        }

    }

    componentDidUpdate(prevProps) {

        if (prevProps.form.headerLinkClicked !== this.props.form.headerLinkClicked) {
            if (this.props.form.headerLinkClicked) {
                this.unsavedDataPrompt('/Home')
            }
        }
    }

    render() {

        return (
            <React.Fragment>
                <div id="form-container">
                    {this.state.showPopup &&
                        <div tabindex="0" className="popup-container" onBlur={this.blurHandler}>
                            <div tabindex="0" className="popup">
                                <div tabindex="0" className="popup-head">
                                    {this.state.showSubmitPopup ? <span>Form has been submitted</span> : <span>Saved successfully</span>}
                                </div>
                                <div className="popup-body" >
                                    <span>Your Title has been saved</span>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="table-header">

                        <h4><Link className="headerlink-Button" type="button" to={'/Collections'}>Titles</Link>›
                            <Link className="headerlink-Button" type="button" to={{}}>{this.state.titleName}</Link>›
                            Title
                        </h4>
                        <h3>{this.state.period}</h3>
                        <div>

                            <Link className="back-link" style={{ margin: '0px', borderRadius: '5px' }} to={'/Home'}>&lsaquo; Back</Link>
                        </div>
                    </div>

                    <div id="agency-info" className="form-section" style={{ padding: '30px', paddingBottom: '250px' }}>
                        <h4>Title Information</h4>

                        <div>
                            <label>Title Name</label>
                            <input
                                name="titleName"
                                type="text"
                                value={this.state.titleName} disabled
                            />
                        </div>
                        <div>
                            <label>Publisher</label>
                            <input
                                name="publisher"
                                type="text"
                                value={this.state.publisher} disabled
                                disabled
                            />
                        </div>
                        <div id="half-left">
                            <label>Frequency</label>
                            <input
                                name="frequency"
                                type="text"
                                value={this.state.frequency} disabled
                            />
                        </div>
                        <div id="half-right">
                            <label>Publication Type</label>
                            <input
                                name="pubtype"
                                type="text"
                                value={this.state.pubtype} disabled
                            />
                        </div>
                        <div id="half-left">
                            <label>Shelf Number</label>
                            <input
                                name="shelfNumber"
                                type="text"
                                value={this.state.shelfNumber} disabled
                            />
                        </div>
                        <div id="half-right">
                            <label>Author</label>
                            <input
                                name="author"
                                type="text"
                                value={this.state.author} disabled
                            />
                        </div>
                        <div id="half-left">
                            <label>Call Number</label>
                            <input
                                name="callNumber"
                                type="text"
                                value={this.state.callNumber} disabled
                            />
                        </div>
                        <div id="half-right">
                            <label>Availability</label>
                            <input
                                name="availability"
                                type="text"
                                value={this.state.availability} disabled
                            />
                        </div>

                    </div>

                    <div className="button-menu">

                        <ReactToPrint
                            copyStyles={false}
                            trigger={() => <button type="button" id={!this.state.isRequisition ? 'hidden' : undefined}>Print</button>}
                            content={() => this.componentRef}
                            pageStyle={{ size: 'auto', margin: '0px' }}
                        />
                    </div>

                </div>
            </React.Fragment>
        )
    }
}




export default connect(
    (state) => {
        const { form } = state;
        return {
            form
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, formActionCreators), dispatch)
        }
    }
)(TitleContainer);
