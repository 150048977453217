import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../scripts/store/Login';
import { formActionCreators } from '../scripts/store/Form';
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DrawerComp from './DrawerComp';
import { useMediaQuery, useTheme } from "@mui/material";

const Header = (props) => {
    const [value, setValue] = useState();
    const theme = useTheme();
    console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
    console.log(isMatch);

    const useStyles = makeStyles((theme) => ({
        toolBar: {
            margin: "auto",
            maxWidth: 1205,
            width: "100%",
            textDecoration: 'none'
        },
        grid: {
            width: '100%',
            margin: '0px',
            paddingLeft: '62px',
            paddingRight: '100px'
        },
        container: {
            maxWidth: '1300px'

        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            background: theme.palette.success.light
        }
    }));
    const classes = useStyles();

    const checkForUnsavedChangesOnForm = !props.login.isAuthenticated ?
        <a href="/Home" style={{ cursor: 'pointer', marginTop: '14px', color: 'white', textDecoration: 'none' }}>
            <h1>Legislative Reference Center</h1>
        </a>
        :
        <a href="/Publishers" style={{ cursor: 'pointer', marginTop: '14px', color: 'white' }}>
            <h1>Legislative Reference Center</h1>
        </a>
    return (
        <header>
            <Box sx={{ display: "flex" }}>
                <AppBar component="nav" sx={{ bgcolor: "#507b98" }}>
                    <Toolbar className={classes.toolBar}>
                        {isMatch ? (
                            <>
                                <Typography>
                                    Legislative Reference Center
                                </Typography>
                                <DrawerComp />
                            </>
                        ) : (
                            <>
                                <Typography noWrap
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                                >
                                    {checkForUnsavedChangesOnForm}
                                </Typography>
                                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                                    {props.login.isAuthenticated && <Link to="/Publishers" className="MenuBtn">Publishers</Link>}
                                    {props.login.isAuthenticated && <Link to="/Titles" className="MenuBtn">Titles</Link>}
                                    {props.login.isAuthenticated && <Link to="/Invoices" className="MenuBtn">Invoices</Link>}
                                    {props.login.isAuthenticated && <Link to="/Reports" className="MenuBtn">Reports</Link>}
                                    {props.login.isAuthenticated && <button onClick={props.actions.logoutUser}>Log Out</button>}
                                    {!props.login.isAuthenticated && <Link to="/History" className="MenuBtn">Legislative History</Link>}
                                    {!props.login.isAuthenticated && <Link to="/Resources" className="MenuBtn">Legislative Resources</Link>}
                                    {!props.login.isAuthenticated && <Link to="/Collections" className="MenuBtn">Collections</Link>}
                                    {!props.login.isAuthenticated && <Link to="/About" className="MenuBtn">About</Link>}
                                    {!props.login.isAuthenticated && <Link to="/Login" className="MenuBtn">Login</Link>}
                                </Box>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                </Box>
            </Box>
        </header>
    );
}

export default withRouter(connect(
    (state) => {
        const { login } = state;
        return {
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, formActionCreators), dispatch)
        }
    }
)(Header))