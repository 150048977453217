import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { gridActionCreators } from '../scripts/store/Grid';
import { authActionCreators } from '../scripts/store/Login';
import '../stylesheets/grid.css';
import Select from 'react-select';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactTable from 'react-table-6'
import "react-table-6/react-table.css";
import { Link } from "react-router-dom";

class Collections extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            selectedRow: '',
            rows: [],
            filteredRows: [],
            selectedRows: [],
            selectAll: false,
            filterOptions: [],
            publisherFilter: 'all',
            contractTypeFilter: 'all',
            prefix: '',
            suggestion: '',
            pubTypes: [
                {
                    value: 'Treatise',
                    label: 'Treatise'
                },
                {
                    value: 'Encyclopedia',
                    label: 'Encyclopedia'
                },
                {
                    value: 'Code Service',
                    label: 'Code Service'
                },
                {
                    value: 'Book',
                    label: 'Book'
                },
                {
                    value: 'Newspaper',
                    label: 'Newspaper'
                }
            ],
            selectedpubType: 'All',
            selectedTitleSearch: ''

        };

        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.filterAll = this.filterAll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.actions.getPublicTitles()
            .then(() => {
                let rows = this.props.grid.rowList;
                //Add the index (for finding the row) and the checked property (for toggling the checkbox)
                rows.forEach(title => {
                    title.label = title.titleName;
                    title.value = title.titleID;
                });
                console.log(rows);
                this.setState(state => (
                    {
                        rows: rows,
                        filteredRows: rows
                    }
                ));
            })
    }

    handleTitleChange(title) {
        //this.props.history.push("/Title/" + val.titleID);
        console.log(title)
        const filteredList = this.state.rows;
        this.setState(state => (
            {
                filteredRows: filteredList.filter((item) => item.titleID == title.value)
            }
        ));
        console.log(this.state.filteredRows)
    }
    filterAll(event) {
        console.log(event.target.value);
        const value = event.target.value;
        const filteredList = this.state.filteredRows;
        console.log(filteredList);
        this.setState(state => ({
            filteredRows: filteredList.filter((item) => item.titleName.toLowerCase().startsWith(value.toLowerCase()))
        }
        ));
    }


    handleChange(event) {
        console.log(event.target.value);
        console.log(event.target.name);
        const value = event.target.value;
        const name = event.target.name;
        const filteredList = this.state.filteredRows;
        console.log(this.state.selectedpubType)
        if (name == "titleName") {
            //console pubtype
            if (value == "") {
                console.log(this.state.selectedpubType)
                if (this.state.selectedpubType == "All") {
                    this.setState(state => ({
                        filteredRows: this.state.rows,
                        selectedTitleSearch: ''
                    }));
                } else {
                    this.setState(state => ({
                        filteredRows: this.state.rows.filter((item) => item.pubtype == this.state.selectedpubType),
                        selectedTitleSearch: ''
                    }));
                }
            }
            else {
                this.setState(state => ({
                    filteredRows: filteredList.filter((item) => item.titleName.toLowerCase().startsWith(value.toLowerCase())),
                    selectedTitleSearch: value
                }));
            }
        }
        else {
            console.log(this.state.selectedpubType)
            if (value != "All") {
                if (this.state.selectedpubType != "") {
                    console.log("here ")
                    console.log(this.state.selectedpubType)
                    this.setState(state => ({
                        filteredRows: this.state.rows.filter((item) => item.pubtype == value && item.titleName.toLowerCase().startsWith(this.state.selectedTitleSearch.toLowerCase())),
                        selectedpubType: value
                    }));
                }
                else {
                    this.setState(state => ({
                        filteredRows: this.state.rows.filter((item) => item.pubtype == value),
                        selectedpubType: value
                    }));
                }
            } else {
                if (this.state.selectedTitleSearch != "") {
                    this.setState(state => ({
                        filteredRows: this.state.rows.filter((item) => item.pubtype == value && item.titleName.toLowerCase().startsWith(this.state.selectedTitleSearch.toLowerCase())),
                        selectedpubType: value
                    }));
                } else {
                    this.setState(state => ({
                        filteredRows: this.state.rows,
                        selectedpubType: value
                    }));
                }
            }
        }
    }
    render() {
        
        const columns = [{
            Header: 'Title Name',
            accessor: 'titleName'

        }, {
            Header: 'Publication Type',
            accessor: 'pubtype',
            width: 150
        }, {
            Header: 'Shelf Number',
            accessor: 'shelfNumber',
            width: 150
        }, {
            Header: 'Availability',
            accessor: 'Availability',
            width: 120
        },
        {
            property: 'actions',
            width: 40,
            header: {
                label: 'Actions'
            },
            Cell: row => {
                return (

                    <Link title="Open Title" to={`/Title/${row.original.titleID}`} className="icon paper"></Link>

                )
            }
        }]
        return (
            <div>
                <div className="header-half">
                    <h1>Search Collections</h1>
                </div>


                <div className="toolbar search-bar">
                    <div className="dlas-forms">
                        <div>
                            <label>Publication Type</label>
                            <select name="pubtype" value={this.state.value} onChange={this.handleChange}>
                                <option value="All">All</option>
                                <option value="Treatise">Treatise</option>
                                <option value="Encyclopedia">Encyclopedia</option>
                                <option value="Encyclopedia">Encyclopedia</option>
                                <option value="Code Service">Code Service</option>
                                <option value="Book">Book</option>
                                <option value="Newspaper">Newspaper</option>
                            </select>
                        </div>
                        <div>
                            <label>Title Name</label>
                            <input name="titleName" value={this.state.filterAll} onChange={this.handleChange} />
                        </div>
                    </div>
                </div>


                <div className="grid-choice" style={{ marginTop: '0px' }}>
                    <ReactTable
                        data={this.state.filteredRows}
                        columns={columns}
                        defaultPageSize={15}
                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    />
                </div>
                <div className="content">

                </div>
                <div class="push"></div>
            </div>
        )
    }
}








function getUrlParameter(name, params) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(params);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default connect(
    (state) => {
        const { grid, login } = state;
        return {
            grid,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, gridActionCreators, authActionCreators), dispatch)
        }
    }
)(Collections);