const idToken = 'JCAR_ID_TOKEN';

const getRowRequest = 'GET_ROW_REQUEST';
const getRowFailure = 'GET_ROW_FAILURE';
const getRowSuccess = 'GET_ROW_SUCCESS';

const deleteReportFailure = 'DELETE_REPORT_FAILURE';
const deleteReportSuccess = 'DELETE_REPORT_SUCCESS';

const getPdfFailure = 'GET_PDF_FAILURE';
const getPdfSuccess = 'GET_PDF_SUCCESS';

const initialState = {
    rowList: [],
    deleteMessage: '',
    pdf: '',
    pdfMessage: ''
}
function requestBody(url, method, headers, body, params) {
    let config = { method: method }
    if (body) config.body = JSON.stringify(body);
    if (headers) config.headers = headers;
    return fetch(url, config)
        .then(response => {
            if (response.status == 204) {
                throw [2, 'No Content'];
            } else if (response.status > 400) {
                throw [1, response.text()];
            }
            console.log(response)
            return response

        })
        .then(response =>
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [0, data]
        })
        .catch(err => err);

}
function request(url, method, headers, body, params) {
    let config = {
        method: method,
        headers: headers,
    }
    if (body) {
        config.body = JSON.stringify(body)
    }
    console.log("did I make it here2")
    console.log(url + params)
    console.log(body)
    return fetch(url + params, config)
        .then(response => {
            if (response.status == 204) {
                throw [2, 'No Content'];
            } else if (response.status > 400) {
                throw [1, response.text()];
            }
            return response

        })
        .then(response =>
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [0, data]
        })
        .catch(err => err);

}

export const gridActionCreators = {

    errorDelete: (message) => ({
        type: deleteReportFailure,
        deleteMessage: message
    }),

    receiveDelete: (message) => ({
        type: deleteReportSuccess,
        deleteMessage: message
    }),

    deleteReport: (id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        const body = jwt;
        return dispatch => {
            return request('/api/Contract/Contracts/', 'DELETE', headers, body, id)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorDelete(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveDelete(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorDelete('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },

    errorPdf: (message) => ({
        type: getPdfFailure,
        pdfMessage: message
    }),

    receivePdf: (message) => ({
        type: getPdfSuccess,
        pdf: message
    }),

    getPdf: (id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        const body = '';
        return dispatch => {
            return request('/api/reports/pdf/', 'GET', headers, body, id + '?jwt=' + jwt)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorPdf(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receivePdf(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorPdf('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },

    errorRows: (message) => ({
        type: getRowFailure,
        rowList: [],
        rowMessage: message
    }),

    receiveRows: (responseObj) => ({
        type: getRowSuccess,
        rowList: responseObj,
    }),
    getPublisherReport: (form) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        form.jwt = jwt;
        const body = form;
        const params = '';

        return dispatch => {

            return requestBody('/api/Report/PublisherReport', 'PUT', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getFiscalReport: (form) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        form.jwt = jwt;
        const body = form;
        const params = '';

        return dispatch => {

            return requestBody('/api/Report/FiscalReport', 'PUT', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getRows: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        const body = '';
        const params = '?jwt=' + jwt;
        return dispatch => {

            return request('/api/Contract/Contracts', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getTitles: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        const body = '';
        const params = '?jwt=' + jwt;
        return dispatch => {

            return request('/api/Company/Titles', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getPublicTitles: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        const body = '';
        const params = '';
        return dispatch => {

            return request('/api/TitlePublic/Titles', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getInvoices: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        const body = '';
        const params = '?jwt=' + jwt;
        return dispatch => {

            return request('/api/Invoice/Invoices', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getYears: () => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };
        const body = '';
        const params = '';
        return dispatch => {

            return request('/api/Comapny/Years', 'GET', headers, body, params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getPublisherTitles: (id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };

        const params = id + '?jwt=' + jwt;
        return dispatch => {

            return request('/api/Contract/PublisherTitles/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    },
    getTitleInvoices: (id) => {
        const jwt = sessionStorage.getItem(idToken)
        const headers = { "Content-Type": "application/json; charset=utf-8" };

        const params = id + '?jwt=' + jwt;
        return dispatch => {

            return request('/api/Contract/TitleInvoices/', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === 1) {
                        dispatch(gridActionCreators.errorRows(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === 0) {
                        // Dispatch the success action
                        dispatch(gridActionCreators.receiveRows(result[1]))
                    } else if (result[0] === 2) {
                        dispatch(gridActionCreators.errorRows('No Content'));
                        return Promise.reject(result[1])
                    }
                })
                .catch(err => console.log(err));
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case deleteReportFailure:
            return Object.assign({}, state, {
                deleteMessage: action.deleteMessage
            })
        case deleteReportSuccess:
            return Object.assign({}, state, {
                deleteMessage: action.deleteMessage,
            })
        case getPdfFailure:
            return Object.assign({}, state, {
                pdfMessage: action.pdfMessage
            })
        case getPdfSuccess:
            return Object.assign({}, state, {
                pdf: action.pdf,
            })
        case getRowRequest:
            return Object.assign({}, state, {
                rowList: action.rows
            })
        case getRowFailure:
            return Object.assign({}, state, {
                isFetching: false,
                rowMessage: action.rowMessage
            })
        case getRowSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                rowList: action.rowList,
                rowMessage: ''
            })
        default:
            return state;
    }
};