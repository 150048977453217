import React from 'react';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import * as sort from 'sortabular'
import orderBy from 'lodash/orderBy';
import * as resolve from 'table-resolver';
import { Link } from "react-router-dom";
import * as Table from 'reactabular-table';
import * as select from 'selectabular';
import { compose } from 'redux';
import { gridActionCreators } from '../scripts/store/Grid';
import { authActionCreators } from '../scripts/store/Login';
import '../stylesheets/grid.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"
//import 'bootstrap/dist/css/bootstrap.min.css';
import firstPicture from '../images/1resize.jpg'
import libraryPicture from '../images/library.jpg'
import servicesPicture from '../images/meta-chart.jpg'
import ourmissionpicture from '../images/ourmission.jpg'
const items = [
    {
        id: 0,
        name: 'Cobol'
    },
    {
        id: 1,
        name: 'JavaScript'
    },
    {
        id: 2,
        name: 'Basic'
    },
    {
        id: 3,
        name: 'PHP'
    },
    {
        id: 4,
        name: 'Java'
    }
]
class History extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            selectedRow: '',
            rows: [],
            filteredRows: [],
            selectedRows: [],
            selectAll: false,
            filterOptions: [],
            publisherFilter: 'all',
            contractTypeFilter: 'all',
            prefix: '',
            suggestion: ''
        };
        this.filterOptions = this.filterOptions.bind(this);
        this.selectCallback = this.selectCallback.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnHover = this.handleOnHover.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.formatResult = this.formatResult.bind(this);
    }

    componentDidMount() {

    }
    handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    handleOnHover = (result) => {
        // the item hovered
        console.log(result)
    }

    handleOnSelect = (item) => {
        // the item selected
        console.log(item)
    }

    handleOnFocus = () => {
        console.log('Focused')
    }

    formatResult = (item) => {
        return item
        // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    }


    onChange() { }
    filterOptions(statename, value) {
        let filter = value.value;
        if (statename == 'selectedYear') {
            this.setState(
                {
                    yearFilter: filter
                }, function () { this.updateFilter(); }
            );
        } else if (statename == 'selectedContractType') {
            this.setState(
                {
                    contractTypeFilter: filter
                }, function () { this.updateFilter(); }
            );
        }

    }

    handleFilterChange(target) {
        let filter = target.value;
        console.log(filter);
        console.log(target.name);
        if (target.name === 'publisher-Filter') {
            this.setState(
                {
                    publisherFilter: filter
                }, function () { this.updateFilter(); }
            );
        }
    }

    updateFilter() {
        const { publisherFilter } = this.state;
        //Set the users prefrences
        localStorage.setItem('filterpref', JSON.stringify({
            publisherFilter: publisherFilter
        }));
        //If both the filters are set to all then show all the rows
        if (publisherFilter == 'all') {
            this.setState(
                { filteredRows: this.state.rows }
            );
        } else {
            //Uses select library
            //Creates a variable that is used to return the list of rows that satisfy the condition
            const filter = row => {
                if ([row.publisher, 'all'].indexOf(publisherFilter) != -1) return row
            }
            const { rows, selectedRows: result } = select.rows(filter)(this.state.rows);

            //Sets filteredRows to the result. This updates what rows the user can see
            this.setState(
                { filteredRows: result }
            );
        }
    }

    //updateFilter() {
    //    const { yearFilter, contractTypeFilter } = this.state;

    //    if (yearFilter == 'all' && contractTypeFilter == 'all') {
    //        this.setState(
    //            { filteredRows: this.state.rows }
    //        );
    //    } else {
    //        const filter = row => {
    //            if ([row.year, 'all'].indexOf(yearFilter) != -1 && [row.contractTypeID, 'all'].indexOf(contractTypeFilter) != -1) return row
    //        }
    //        const { rows, selectedRows: result } = select.rows(filter)(this.state.rows);

    //        //Sets filteredRows to the result. This updates what rows the user can see
    //        this.setState(
    //            { filteredRows: result }
    //        );
    //    }
    //}

    selectCallback(state, value) {
        this.setState({
            [state]: value
        }, () => {
            this.getTableData();
        });
    }


    deleteRow(row) {
        console.log("made it to confirmdelete")
        console.log(row)
        console.log(this.state.selectedRow)
        this.setState({
            selectedRow: row
        }, function () { this.togglePopup(); });
    }
    confirmDelete() {
        console.log("made it to confirmdelete")
        console.log(this.state.selectedRow)
        this.props.actions.deleteReport(this.state.selectedRow.contractID)
            .then(() => {
                const selectedRow = this.state.selectedRow;
                const rows = [...this.state.rows];
                const filteredRows = [...this.state.filteredRows];

                const filteredRowIndex = filteredRows.findIndex(row => selectedRow.contractID === row.contractID);
                const rowIndex = rows.findIndex(row => selectedRow.contractID === row.contractID);
                rows.splice(rowIndex, 1);
                filteredRows.splice(filteredRowIndex, 1);
                this.setState({
                    rows: rows,
                    filteredRows: filteredRows,
                    selectedRow: ''
                });
            })
            .then(() => this.togglePopup());
    }

    togglePopup() {
        console.log("did we make it here")
        this.setState(state => ({
            showPopup: !state.showPopup
        }));
    }

    render() {
        return (
            <div>
                <div className="table-header">
                </div>
                <div className="grid-choice">
                    <h1> Legislative History</h1>
                                <p><strong>Release
                                    of legislative draft files</strong>
                                    <p>Effective July
                                        1, 2017, Virginia Code &sect; 30-28.18 (B) was amended to specify
                                        that the legislative draft files are property of the requester,
                                        to be released only with the specific approval of the requester.
                                        In the past, files after 1989 were open to the public if the legislation
                                        was enacted. The relevant section follows: </p>
                                    <blockquote>
                                        <p>&quot;B.
                                            All legislative drafting requests and accompanying documents shall
                                            be maintained by the Division as permanent records. Each of these
                                            separate files shall be considered the property of the requester
                                            and no one other than members of the Division staff shall have
                                            access to any such file without the specific approval of the requester.&quot;</p>
                                    </blockquote>
                                    <p> The Division has created a form for seeking permission from owners of legislative
                                        drafting files to release such files. To obtain the contents of
                                        a legislative drafting file, please fill out the <a href="Requester-form.pdf" download>request form</a> and email your request to Lily Jones
                                        at <a href="mailto:ljones@dls.virginia.gov">ljones@dls.virginia.gov</a>.
                                        Once you have submitted this information, we will forward a completed
                                        &quot;request for release&quot; form to the owner of the drafting
                                        file. We will inform you if the owner has granted permission to
                                        release the file. If permission is granted, we will forward the
                                        contents that have been approved for release. </p>
                                    <p><strong>Legislative
                                        history / Bill tracking information</strong></p>
                                    <p>Please be reminded that the legislative history of a bill can be found by searching
                                        the <a href="http://lis.virginia.gov/" target="_blank">Legislative
                                            Information System</a> (LIS). Keeping in mind that legislative intent
                                        is not officially recorded in Virginia, your research to piece together
                                        the intent of a section should include:</p>
                                    <p>1. Searching
                                        for <a href="http://leg2.state.va.us/dls/h&sdocs.nsf/Published%2Bby%2BYear?OpenForm&StartKey=2017&ExpandView" target="_blank">Reports
                                            to the General Assembly</a> on a topic.</p>
                                    <p>2. Searching
                                        for Fiscal Impact Statements for a bill, if applicable, through
                                        the <a href="https://lis.virginia.gov/" target="_blank">LIS</a>.</p>
                                    <p>2. Videos of
                                        floor sessions from the House and Senate are archived on the General
                                        Assembly's website, beginning with the 2017 Session. </p>
                                    <blockquote>
                                        <p>House video: <a href="https://virginiageneralassembly.gov/house/chamber/chamberstream.php" target="_blank">https://virginiageneralassembly.gov/house/chamber/chamberstream.php</a></p>
                                        <p>Senate video: <a href="http://virginia-senate.granicus.com/ViewPublisher.php?view_id=3" target="_blank">http://virginia-senate.granicus.com/ViewPublisher.php?view_id=3</a></p>
                                     </blockquote>
                                            <p>3. Searching
                                                newspaper articles, law review articles, journals or secondary sources
                                                relating to the legislation.</p>
                                            <p>If you have questions, feel free to call Lily Jones, Reference Center Director, at (804) 698-1888.</p>

                        </p>
                </div>

                <div class="push"></div>
            </div>
        )
    }
}








function getUrlParameter(name, params) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(params);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default connect(
    (state) => {
        const { grid, login } = state;
        return {
            grid,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, gridActionCreators, authActionCreators), dispatch)
        }
    }
)(History);