import React from 'react';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import * as sort from 'sortabular'
import orderBy from 'lodash/orderBy';
import * as resolve from 'table-resolver';
import { Link } from "react-router-dom";
import * as Table from 'reactabular-table';
import * as select from 'selectabular';
import { compose } from 'redux';
import { gridActionCreators } from '../scripts/store/Grid';
import { authActionCreators } from '../scripts/store/Login';
import '../stylesheets/grid.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"


class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            selectedRow: '',
            rows: [],
            filteredRows: [],
            selectedRows: [],
            selectAll: false,
            filterOptions: [],
            publisherFilter: 'all',
            contractTypeFilter: 'all',
            prefix: '',
            suggestion: ''
        };
        this.filterOptions = this.filterOptions.bind(this);
        this.selectCallback = this.selectCallback.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnHover = this.handleOnHover.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.formatResult = this.formatResult.bind(this);
    }

    componentDidMount() {

    }
    handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    handleOnHover = (result) => {
        // the item hovered
        console.log(result)
    }

    handleOnSelect = (item) => {
        // the item selected
        console.log(item)
    }

    handleOnFocus = () => {
        console.log('Focused')
    }

    formatResult = (item) => {
        return item
        // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    }


    onChange() { }
    filterOptions(statename, value) {
        let filter = value.value;
        if (statename == 'selectedYear') {
            this.setState(
                {
                    yearFilter: filter
                }, function () { this.updateFilter(); }
            );
        } else if (statename == 'selectedContractType') {
            this.setState(
                {
                    contractTypeFilter: filter
                }, function () { this.updateFilter(); }
            );
        }

    }

    handleFilterChange(target) {
        let filter = target.value;
        console.log(filter);
        console.log(target.name);
        if (target.name === 'publisher-Filter') {
            this.setState(
                {
                    publisherFilter: filter
                }, function () { this.updateFilter(); }
            );
        }
    }

    updateFilter() {
        const { publisherFilter } = this.state;
        //Set the users prefrences
        localStorage.setItem('filterpref', JSON.stringify({
            publisherFilter: publisherFilter
        }));
        //If both the filters are set to all then show all the rows
        if (publisherFilter == 'all') {
            this.setState(
                { filteredRows: this.state.rows }
            );
        } else {
            //Uses select library
            //Creates a variable that is used to return the list of rows that satisfy the condition
            const filter = row => {
                if ([row.publisher, 'all'].indexOf(publisherFilter) != -1) return row
            }
            const { rows, selectedRows: result } = select.rows(filter)(this.state.rows);

            //Sets filteredRows to the result. This updates what rows the user can see
            this.setState(
                { filteredRows: result }
            );
        }
    }

    //updateFilter() {
    //    const { yearFilter, contractTypeFilter } = this.state;

    //    if (yearFilter == 'all' && contractTypeFilter == 'all') {
    //        this.setState(
    //            { filteredRows: this.state.rows }
    //        );
    //    } else {
    //        const filter = row => {
    //            if ([row.year, 'all'].indexOf(yearFilter) != -1 && [row.contractTypeID, 'all'].indexOf(contractTypeFilter) != -1) return row
    //        }
    //        const { rows, selectedRows: result } = select.rows(filter)(this.state.rows);

    //        //Sets filteredRows to the result. This updates what rows the user can see
    //        this.setState(
    //            { filteredRows: result }
    //        );
    //    }
    //}

    selectCallback(state, value) {
        this.setState({
            [state]: value
        }, () => {
            this.getTableData();
        });
    }


    deleteRow(row) {
        console.log("made it to confirmdelete")
        console.log(row)
        console.log(this.state.selectedRow)
        this.setState({
            selectedRow: row
        }, function () { this.togglePopup(); });
    }
    confirmDelete() {
        console.log("made it to confirmdelete")
        console.log(this.state.selectedRow)
        this.props.actions.deleteReport(this.state.selectedRow.contractID)
            .then(() => {
                const selectedRow = this.state.selectedRow;
                const rows = [...this.state.rows];
                const filteredRows = [...this.state.filteredRows];

                const filteredRowIndex = filteredRows.findIndex(row => selectedRow.contractID === row.contractID);
                const rowIndex = rows.findIndex(row => selectedRow.contractID === row.contractID);
                rows.splice(rowIndex, 1);
                filteredRows.splice(filteredRowIndex, 1);
                this.setState({
                    rows: rows,
                    filteredRows: filteredRows,
                    selectedRow: ''
                });
            })
            .then(() => this.togglePopup());
    }

    togglePopup() {
        console.log("did we make it here")
        this.setState(state => ({
            showPopup: !state.showPopup
        }));
    }

    render() {
        return (
            <div>
                <div className="table-header">
                </div>
                <div className="menu-choice">
                    <h1>Information Contacts</h1>
                                                        <p><b>Legislative Reference Center</b><br />
                                                        Division of Legislative Services<br />
                                                        201 N. 9th Street<br />
                                                        General Assembly Building, 4th floor<br />
                                                        Richmond, Virginia 23219<br />
                                                        (804) 698-1888<br /><br />

                        <b>Lily Jones, Director</b><br />
                        email contact: <a href="mailto:ljones@dls.virginia.gov">ljones@dls.virginia.gov</a><br /><br />
                        <b>Mary Dale, Records Technician</b><br />
                        email contact: <a href="mailto:mdale@dls.virginia.gov">mdale@dls.virginia.gov</a><br />
                        <a href="https://reference.dls.virginia.gov">https://reference.dls.virginia.gov</a> </p>
                                                        <p><b>Legislative Information Office<br /></b>Capitol, First floor<br />
                                                        Capital Square<br />
                                                        Richmond, Virginia 23219<br />
                                                            (804) 698-1500</p>
                                                        <p><b>Senate Information</b><br />Capitol, First floor<br />
                                                        Senate Room 2<br />
                                                        Capital Square<br />
                                                        Richmond, Virginia 23219<br />
                                                            (804) 698-7410</p>
                                                        <p><b>Library of Virginia<br /></b>9<sup>th</sup> and Broad Streets<br />
                                                                    Richmond, Virginia 23219<br />
                                                                </p>
                </div>

                <div class="push"></div>
            </div>
        )
    }
}








function getUrlParameter(name, params) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(params);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default connect(
    (state) => {
        const { grid, login } = state;
        return {
            grid,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, gridActionCreators, authActionCreators), dispatch)
        }
    }
)(Contact);