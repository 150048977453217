import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { formActionCreators } from '../scripts/store/Form';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactToPrint from 'react-to-print';
import ContractDetails from './ContractDetails';
import "react-datepicker/dist/react-datepicker.css";
import '../stylesheets/form.css';
import FileBase64 from 'react-file-base64';
import { forEach } from 'lodash';

const FORM_ERRORS = 1;

function NoteContainer(props) {

    const noteMap = props.noteDetails.map((noteDetail, index) => {
        return (
            <div key={index}>
                <NoteDetail
                    isDisabled={props.isDisabled}
                    onChange={props.noteContainerChange}
                    noteDetail={noteDetail}
                    delete={props.delete}
                    index={index} />
                {index !== props.noteDetails.length - 1 && <div className="seperator" ><hr /></div>}
            </div>
        )
    });
    return (noteMap);
}

class FormContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            dlasContactName: '',
            dlasContactID: null,
            companyID: null,
            contractType: '',
            contractTypeID: null,
            year: null,
            dataChanged: false,
            id: this.props.match.params.id,
            submitted: false,
            showPopup: false,
            showSubmitPopup: false,
            period: '',
            agencyID: '',
            contractTypes: [],
            dlasContacts: [],
            companies: [],
            existingExemptions: [],
            exemption: '',
            citation: '',
            isRequisition: false,
            combineReq1: false,
            combineReq2: false,
            Type: '',
            contractItem: '',
            contractNumber: '',
            requisitionNumber: '',
            startDate: '',
            endDate: '',
            remainingRenewals: '',
            Price: '',
            companyName: '',
            companyAddress: '',
            companyCity: '',
            companyState: '',
            companyZipCode: '',
            companyFax: '',
            companyEmail: '',
            companyFin: '',

            contactName: '',
            contactPhone: '',
            contactFax: '',
            contactEmail: '',
            noteText: '',
            v: [],
            noteDetails: [
                {
                    textID: '',
                    noteText: '',
                    isDeleted: false,
                    files: [
                        {
                            fileName: '',
                            fileUrl: '',
                            mimeType: '',
                            fileID: 0,
                            byteArray: ''
                        }
                    ]
                }
            ],
            paperWeight: '',
            quantity: '',
            Pages: null,
            Volumes: null,
            combineOrderList: [
                {
                    combineOrderID: 0,
                    text: "",
                },
                {
                    combineOrderID: 1,
                    text: "1st",
                },
                {
                    combineOrderID: 2,
                    text: "2nd",
                },
            ],
            combineOrderID: null,
            newCompanyName: '',
            hideText: "hidden",
            hideButton: true,
            headerStyle: {
                float: 'left',
                width: '48%',
                marginTop: '5px',
                padding: '10px'
            },
            accountNum: '',
            publisher: '',
            addr1: '',
            addr2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            fax: '',
            notes: '',
            titlesbyPublisherNumber: ''
        };

        this.props.actions.clickHeaderLink(false)
        this.handleChange = this.handleChange.bind(this);
        this.noteContainerChange = this.noteContainerChange.bind(this);
        this.addNote = this.addNote.bind(this);
        this.getContractData = this.getContractData.bind(this);
        this.savePublisher = this.savePublisher.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.toggleSubmitPopup = this.toggleSubmitPopup.bind(this);
        this.delete = this.delete.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
        this.addCompany = this.addCompany.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.createCompany = this.createCompany.bind(this);
    }

    createCompany() {
        let companyData = {
            ...companyData,
           
            companyName: this.state.companyName,
            companyAddress: this.state.companyAddress,
            companyCity: this.state.companyCity,
            companyState: this.state.companyState,
            companyZipCode: this.state.companyZipCode,
            companyPhone: this.state.companyPhone,
            companyEmail: this.state.companyEmail,
            companyFax: this.state.companyFax,
            companyFin: this.state.companyFin,

            contactName: this.state.contactName,
            contactPhone: this.state.contactPhone,
            contactFax: this.state.contactFax,
            contactEmail: this.state.contactEmail
            
        }
        if (companyData !== FORM_ERRORS) {
          
                this.props.actions.postForms(companyData)
                    .then(() => {
                        if (this.props.form.formPostMessage) {
                            this.setState({
                                id: this.props.form.formPostMessage,
                                dataChanged: false
                            }, function () { this.togglePopup() });
                        }
                    });
            
        }
    }
    handleChange(e) {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({
            [key]: value
        });
    }

    blurHandler() {
        this.setState({
            showPopup: false
        })
    }

    delete(id) {
        this.setState(prevState => ({
            v: prevState.noteDetails.forEach(function (o) {
                let item = o.files.filter(f => f.fileID == id)
                let index = o.files.findIndex(f => f.fileID == id)
                if (index > -1) {
                    item[0].isDeleted = true;
                    o.files[index] = item[0];
                }
            })
        }));
    }

    noteContainerChange(name, value, index) {
        if (this.state.noteDetails[0][name] !== 'undefined') {
            let newNote = this.state.noteDetails;
            newNote[index][name] = value;
            this.setState({
                noteDetails: newNote,
                dataChanged: true
            });
        }
    }

    addNote() {
        //Clicking the add Note button creates a new component with these default values
        const newNote = {
            noteText: '',
            isDeleted: false,
            files: []
        }
        this.setState(state => ({
            noteDetails: [...state.noteDetails, newNote],
            dataChanged: true
        }));
    }

    addCompany() {
        this.setState(state => ({
            hideButton: !state.hideButton,
           
        }));
        if (this.state.hideButton) {
            this.setState({
                headerStyle: {
                    float: 'left',
                    width: '48%',
                    marginTop: '5px',
                    padding: '10px',
                    paddingBottom: '65px'
                }

            });
        } else {
            this.setState({
                headerStyle: {
                    float: 'left',
                    width: '48%',
                    marginTop: '5px',
                    padding: '10px'
                }

            });
        }
       
    }
    getContractData(isSubmitting) {
        let formData = {};
        
        let hasErrors = 0;
        let nonEmptyNotes = [];
        

        formData = {
            ...formData,
            accountNum: this.state.accountNum,
            publisher: this.state.publisher,
            addr1: this.state.addr1,
            addr2: this.state.addr2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            phone: this.state.phone,
            fax: this.state.fax,
            notes: this.state.notes
        }
    
        //If it has errors then don't send any data
        if (hasErrors) {
            this.setState({
                hasErrors: true
            })
            return FORM_ERRORS;
        } else {
            this.setState({
                hasErrors: false
            })
            return formData;
        }
    }



    savePublisher() {
        let formData = this.getContractData(this.state.submitted);
        console.log(formData);
        if (formData !== FORM_ERRORS) {
            //Show the popup once the save is done
            if (this.props.match.params.id) {
                //this is where th save happens
                this.props.actions.saveContracts(formData, this.props.match.params.id)
                    .then(() => {
                        let newNoteDetails = this.state.noteDetails;
                        this.setState({
                            noteDetails: newNoteDetails,
                            dataChanged: false
                        });
                    })
                    .then(() => this.togglePopup());
            } else {
                this.props.actions.postForms(formData)
                    .then(() => {
                        if (this.props.form.formPostMessage) {
                            this.setState({
                                id: this.props.form.formPostMessage,
                                dataChanged: false
                            }, function () { this.togglePopup() });
                        }
                    });
            }
        }
    }

    unsavedDataPrompt(link) {
        if (this.state.dataChanged) {
            this.setState({
                showPrompt: true,
                nextLink: link
            })
        } else {
            this.props.history.push(link);
        }
        this.props.actions.clickHeaderLink(false);
    }

    toggleSubmitPopup() {
        this.setState(state => ({
            showSubmitPopup: !state.showSubmitPopup,
            showPopup: !state.showPopup
        }));
    }

    togglePopup() {
        this.setState(state => ({
            showPopup: !state.showPopup
        }))
        setTimeout(() => { this.props.history.push("/Publisher/" + this.state.id); this.setState({ showPopup: false }) }, 2000);

    }

    toggleCheckboxReq() {
        this.setState(state => ({
            isRequisition: !state.isRequisition
        }));
    }

    handleCompanyChange(e) {
        const selectedCompany = this.state.companies.find(company => company.companyID == e.target.value);
        const key = e.target.name;
        this.setState({
            [key]: e.target.value,

            companyAddress: selectedCompany.companyAddress,
            companyCity: selectedCompany.companyCity,
            companyState: selectedCompany.companyState,
            companyZipCode: selectedCompany.companyZipCode,
            companyFax: selectedCompany.companyFax,
            companyEmail: selectedCompany.companyEmail,
            companyFin: selectedCompany.companyFin,

            contactName: selectedCompany.contactName,
            contactPhone: selectedCompany.contactPhone,
            contactFax: selectedCompany.contactFax,
            contactEmail: selectedCompany.contactEmail
        });
    }
    componentDidMount() {
        //Scroll to top of page since clicking a link far down on the grid will keep the same scroll position
        window.scrollTo(0, 0);
        if (this.props.match.params.id) {
            //Form has an ID so this is an update, set the data for that form
            this.props.actions.getForm(this.props.match.params.id)
                .then(() => {
                    if (this.props.form.formGetMessage) {
                        const formData = this.props.form.formGetMessage;
                       
                        this.setState({
                            publisherID: formData.publisherID,
                            accountNum: formData.accountNum,
                            publisher: formData.publisher,
                            addr1: formData.addr1,
                            addr2: formData.addr2,
                            city: formData.city,
                            state: formData.state,
                            zip: formData.zip,
                            phone: formData.phone,
                            fax: formData.fax,
                            notes: formData.notes,
                            titlesbyPublisherNumber: formData.titlesbyPublisherNumber,
                            //contractItem: formData.contractItem,
                            //dlasContact: formData.dlasContact,
                            //year: formData.year,

                            //Type: formData.contractType,
                            //contractNumber: formData.contractNumber,
                            //startDate: formData.startDate,
                            //endDate: formData.endDate,
                            //remainingRenewals: formData.remainingRenewals,
                            //Price: formData.price,

                            //companyID: formData.companyID,
                            //companyName: formData.companyName,
                           
                            //isRequisition: formData.isRequisition,
                            //combineReq1: formData.combineReq1,
                            //combineReq2: formData.combineReq2,
                            //contractType: formData.contractType,
                            //contractTypeID: formData.contractTypeID,
                            //dlasContactName: formData.dlasContactName,
                            //dlasContactID: formData.dlasContactID,
                            //noteDetails: formData.noteDetails,
                            //paperWeight: formData.paperWeight,
                            //quantity: formData.quantity,
                            //Pages: formData.pages,
                            //Volumes: formData.volumes,
                            //combineOrderID: formData.combineOrderID,
                            //requisitionNumber: formData.combineOrderID != 1 ? "Req 109: " + formData.year.toString().substring(2, 4) + "-" + formData.requisitionNumber : formData.requisitionNumber,

                        }, function () {
                            //this.props.actions.getCompanies().then(() => {
                            //    this.setState({
                            //        companies: this.props.form.companies
                            //    }, function () {
                            //        console.log(this.state.companies)
                            //        console.log(this.state.companyID)
                            //        if (this.state.companyID != null) {
                            //            const selectedCompany = this.state.companies.find(company => company.companyID == this.state.companyID);
                            //            this.setState({
                            //                companyAddress: selectedCompany.companyAddress,
                            //                companyCity: selectedCompany.companyCity,
                            //                companyState: selectedCompany.companyState,
                            //                companyZipCode: selectedCompany.companyZipCode,
                            //                companyPhone: selectedCompany.companyPhone,
                            //                companyFax: selectedCompany.companyFax,
                            //                companyEmail: selectedCompany.companyEmail,
                            //                companyFin: selectedCompany.companyFin,

                            //                contactName: selectedCompany.contactName,
                            //                contactPhone: selectedCompany.contactPhone,
                            //                contactFax: selectedCompany.contactFax,
                            //                contactEmail: selectedCompany.contactEmail
                            //            });
                            //        }
                            //    })

                            //})
                        });
                    }
                })
    
            //this.props.actions.getContractTypes().then(() => {
            //    const companyData = this.props.form.getCompaniesMessage;           
            //    this.setState({
            //        contractTypes: this.props.form.contractTypes
            //    })
            //});
            //this.props.actions.getDlasContacts().then(() => {
            //    this.setState({
            //        dlasContacts: this.props.form.dlasContacts
            //    })
            //});
          


        }
        else {
            //this.props.actions.getContractTypes().then(() => {
            //    this.setState({
            //        contractTypes: this.props.form.contractTypes
            //    })
            //});
            //this.props.actions.getDlasContacts().then(() => {
            //    this.setState({
            //        dlasContacts: this.props.form.dlasContacts
            //    })
            //});
            //this.props.actions.getCompanies().then(() => {
            //    this.setState({
            //        companies: this.props.form.companies
            //    })
            //});
        }

    }

    componentDidUpdate(prevProps) {

        if (prevProps.form.headerLinkClicked !== this.props.form.headerLinkClicked) {
            if (this.props.form.headerLinkClicked) {
                this.unsavedDataPrompt('/Publishers')
            }
        }
    }

    render() {
        let titlesbyPubNumber;
        if (this.state.titlesbyPublisherNumber > 0) {
            titlesbyPubNumber = <Link id="form-Button" to={'/PublisherTitles/' + this.state.publisherID} type="button">View titles ({this.state.titlesbyPublisherNumber})</Link>;
        } else {
            titlesbyPubNumber = <Link id="form-Button" to={'/AddNewTitle/' + this.state.publisherID} type="button">Add Title +</Link>;
        }
        return (
            <React.Fragment>
                <div id="form-container">
                    {this.state.showPopup &&
                        <div tabindex="0" className="popup-container" onBlur={this.blurHandler}>
                            <div tabindex="0" className="popup">
                                <div tabindex="0" className="popup-head">
                                    {this.state.showSubmitPopup ? <span>Form has been submitted</span> : <span>Saved successfully</span>}
                                </div>
                                <div className="popup-body" >
                                    <span>Your Publisher has been saved</span>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="table-header">
                        {!this.props.match.params.id &&
                            <h4><Link className="headerlink-Button" type="button" to={'/Publishers'}>Publishers</Link>>
                                Add New Publisher</h4>
                        }
                        {this.props.match.params.id &&
                            <h4><Link className="headerlink-Button" type="button" to={'/Publishers'}>Publishers</Link>>
                                Edit Publisher</h4>
                        }
                        <h3>{this.state.period}</h3>
                        <div>
                        
                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>&lsaquo; Back</button>
                        </div>
                    </div>
                
                    {this.props.match.params.id &&
                        <div style={{ marginBottom: '22px' }}>
                            {titlesbyPubNumber}
                        </div>
                    }
                    <div id="agency-info" className="form-section">
                        <div style={{ margin: '0 auto', width: '95%' , paddingBottom: '5px'}}>
                          
                          
                            <h4>Publisher Information</h4>
                            <div className="multi-row fifth">
                                
                                <div>
                                    <label>Publisher</label>
                                    <input
                                        name="publisher"
                                        type="text"
                                        value={this.state.publisher} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-left">
                                    <label>Account Number</label>
                                    <input
                                        name="accountNum"

                                        type="text"
                                        value={this.state.accountNum} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Phone</label>
                                    <input
                                        name="phone"
                                        type="text"
                                        value={this.state.phone} onChange={this.handleChange}
                                    />
                                </div>
                                <div>
                                    <label>FAX</label>
                                    <input
                                        name="fax"
                                        type="text"
                                        value={this.state.fax} onChange={this.handleChange}
                                    />
                                </div>
                                <div>
                                    <label>Address 1</label>
                                    <input
                                        name="addr1"
                                        type="text"
                                        value={this.state.addr1} onChange={this.handleChange}
                                    />
                                </div>
                                <div>
                                    <label>Address 2</label>
                                    <input
                                        name="addr2"
                                        type="text"
                                        value={this.state.addr2} onChange={this.handleChange}
                                    />
                                </div>
                                <div>
                                    <label>City</label>
                                    <input
                                        name="city"
                                        type="text"
                                        value={this.state.city} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-left">
                                    <label>State</label>
                                    <input
                                        name="state"

                                        type="text"
                                        value={this.state.state} onChange={this.handleChange}
                                    />
                                </div>
                                <div id="half-right">
                                    <label>Zipcode</label>
                                    <input
                                        name="zip"
                                        type="text"
                                        value={this.state.zip} onChange={this.handleChange}
                                    />
                                </div>
                                <div>
                                    <h4>Notes</h4>
                                    <div id="noteDetail">
                                        <textarea name="notes" onChange={this.handleChange} value={this.state.notes} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="submit-section">
                        <button onClick={this.savePublisher} type="button">Save</button>
                    </div>

                    <div className="button-menu">
                        <div style={{ float: 'right' }}>
                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>&lsaquo; Back</button>
                        </div>
                        {this.props.match.params.id &&
                            <div style={{ float: 'left' }}>
                            <button className="back-link" onClick={() => this.unsavedDataPrompt('/Publishers')}>Delete Publisher <span className="icon destroy"></span></button>
                            </div>}
                        {this.state.hasErrors && <span className="error-text">Required fields are missing</span>}
                        <ReactToPrint
                            copyStyles={false}
                            trigger={() => <button type="button" id={!this.state.isRequisition ? 'hidden' : undefined}>Print</button>}
                            content={() => this.componentRef}
                            pageStyle={{ size: 'auto', margin: '0px' }}
                        />
                    </div>
                    <ContractDetails
                        {...this.state}
                        ref={el => (this.componentRef = el)}
                        agencies={this.props.form.dlasContacts}
                        period={this.state.companyCity} />
                </div>
            </React.Fragment>
        )
    }
}

class NoteDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            noteFiles: []

        }
        this.getFiles = this.getFiles.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.onChange(e.target.name, e.target.value, this.props.index);
    }

    removeNote() {
        this.props.onChange('isDeleted', !this.props.noteDetail.isDeleted, this.props.index)
    }
    delete(id) {
        this.props.delete(id);
    }

    getFiles(files) {
        this.setState({ files: files })     
        files.forEach(f =>
            this.props.noteDetail.files.push({ "fileID": 0, "fileName": f.name, "mimeType": f.type, "byteArray": f.base64.split(',')[1], "noteID": this.props.noteDetail.noteID, "isDeleted": this.props.noteDetail.isDeleted })
        )
    }

    render() {

        const isDisabled = this.props.isDisabled || this.props.noteDetail.isDeleted;
        return (
            <div>
                <div className="section-head flex-row">
                    <div className="remove-note-container">
                        {isDisabled ?
                            <span title="Add Note back" onClick={this.removeNote.bind(this)} className="icon addback"></span>
                            :
                            <span title="Remove Note" onClick={this.removeNote.bind(this)} className="icon destroy"></span>
                        }
                    </div>
                </div>

                <div className="section-body">
                    <div className="input-container no-padding-right">
                        <textarea disabled={isDisabled} name="noteText" onChange={this.handleChange} value={this.props.noteDetail.noteText} />
                        <div className="upload-container" disabled={isDisabled}>

                            <fieldset disabled={isDisabled} className="fieldset-upload">
                                <button disabled={isDisabled} className="button label-upload" htmlFor="uploadForm">Attach file</button>
                                <FileBase64 className="upload-input"
                                    multiple={true}
                                    onDone={this.getFiles.bind(this)} />
                            </fieldset>

                        </div>

                        <div class="files-uploaded">
                            <div className="file-upload-desc">
                                {this.state.files.map((file, index) =>
                                    <div className='new-line' disabled={isDisabled}>{file.name}</div>)}
                            </div>
                            {this.props.noteDetail.files.filter(s => s.isDeleted == false).map((noteFile, index) =>

                                <div className='new-line'><a disabled={isDisabled} href={noteFile.fileUrl} download>{noteFile.fileName}</a>  <span title="Delete file" onClick={this.delete.bind(this, noteFile.fileID)} className="icon destroy"></span></div>)}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


export default connect(
    (state) => {
        const { form } = state;
        return {
            form
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, formActionCreators), dispatch)
        }
    }
)(FormContainer);
