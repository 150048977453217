import React from 'react';
import Select, { components } from 'react-select';
import { Link } from "react-router-dom";

class PubTitlesToolbarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.publisherID
        }
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    onFilterChange(e) {
        this.props.onFilterChange(e.target);
    }

    render() {

        const { allRows, publisherFilter, publisherID} = this.props

        return (
            <div className="table-filter">
                <table>
                    <thead><tr>
                        <th id="pagination">
                            <div>
                                <Link to={'/Publisher/' + publisherID} className="button-link" style={{ marginRight: '15px' }}>Publisher Info</Link>
                                <Link to={'/AddNewTitle/' + publisherID} className="button-link">Add Title +</Link>
                            </div>
                        </th>
                        <th id="filters-section">
                            <div>
                                <span className="spanFilter">Filter </span>
                                <select name="publisher-Filter" value={publisherFilter} onChange={this.onFilterChange} >
                                    <option value="all">All Titles</option>
                                    <ListFilter headers={allRows} filter="publisher" />
                                </select>
                            </div>
                        </th>
                        
                    </tr></thead>
                </table>
            </div>
        )
    }
}

function ListFilter(props) {
    let unique = [...new Set(props.headers.map(item => item[props.filter]))];
    //They want the agency filter in alphabetical order
    props.filter === 'publisher' && unique.sort()

    //Used to create  a list of options from an array. The key is just a random number as none of the values are guaranteed to be unique.
    const items = unique.map(header => {
        let key = Math.random();
        return (<option key={key} value={header}>{header}</option>)
    })
    return <React.Fragment>{items}</React.Fragment>
}
export default PubTitlesToolbarComponent;